<template>
  <BaseTemplatePickerDialog
    v-bind="$props"
    :title="$t('fileManagement.cmsDocumentTemplatePicker.title')"
    :templates="templates"
    :isLoading="isLoading"
    :isSaving="isSaving"
    :categories="categories"
    :isCategoriesLoading="isCategoriesLoading"
    v-model="localDialog"
    show-delete
    show-download
    show-category
    @add-template="onAddTemplateClick"
    @init="onInit"
    @filter-change="onFilterChange"
    @download-item="onDownloadItem"
    @delete-item="onDeleteItem"
  />
</template>

<script>
import BaseTemplatePickerDialog from "./BaseTemplatePickerDialog.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseTemplatePickerDialog,
  },
  props: {
    dialog: Boolean,
    group: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapState("cmsDocumentTemplateLibrary", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
      isSaving: (state) => state.isSaving,
    }),
    ...mapState("documentTemplateLibraryCategory", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onInit() {
      this.getCategories();
      this.getTemplates();
    },
    getCategories() {
      this.$store.dispatch(
        "documentTemplateLibraryCategory/getCMSCategories",
        this.group,
      );
    },
    getTemplates(args = {}) {
      this.$store.dispatch("cmsDocumentTemplateLibrary/getTemplates", {
        ...args,
        group: this.group,
      });
    },
    onFilterChange(filter) {
      this.getTemplates(filter);
    },
    onDownloadItem(item) {
      this.$store.dispatch("cmsDocumentTemplateLibrary/downloadItem", item);
    },
    onDeleteItem(item) {
      this.$store.dispatch("cmsDocumentTemplateLibrary/deleteItem", item);
    },
    async onAddTemplateClick(selectedTemplates) {
      const selectedIds = selectedTemplates.map((x) => x.id);
      const body = { ids: selectedIds };
      const params = { group: this.group };
      await this.$store.dispatch("cmsDocumentTemplateLibrary/saveTemplates", {
        body,
        params,
      });
      this.localDialog = false;
    },
  },
};
</script>
