import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getFormLibraryTemplate({ id }) {
  return axios
    .get(`${apiUrl}template-library/form/${id}`)
    .then(({ data }) => {
      return data ? data.template : null;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getFormLibraryTemplateItems({ id }) {
  return axios
    .get(`${apiUrl}template-library/form/${id}/items`)
    .then(({ data }) => {
      return data?.items || [];
    })
    .catch((error) => {
      throw new Error(error);
    });
}
