<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="task"
      @click="handleInteractableClick"
    >
      <template v-if="header.value == filtersMixins_dataFields.STATUS">
        <AppCustomStatusIcon
          model="task"
          :statusId="statusId"
          :loading="task?.isUpdatingStatus"
        />
      </template>
      <template v-if="header.value == filtersMixins_dataFields.NAME">
        <div class="table-cell-content__breakable-long-text">
          {{ task.name }}
        </div>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.COUNT">
        <div class="d-flex justify-end">
          <AppCountChip
            v-for="(countData, index) in countItems"
            v-if="countData.count"
            :key="index"
            :count="countData.count > 99 ? '99+' : countData.count"
            :icon="countData.icon"
            :text="countData.text"
            :class="index === 0 && countData.count ? '' : 'ml-2'"
            @click="onCountChipClick(countData.to)"
          />
        </div>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.RESPONSIBLE">
        <template v-if="task.member">
          <AppUserAvatar :user="task.member" show-name :truncate="22" />
        </template>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.COLLABORATORS">
        {{
          $tc(
            "taskManagement.list.participantAmount",
            Array.isArray(task.collaborators) ? task.collaborators.length : 0,
          )
        }}
      </template>
      <template v-if="header.value == filtersMixins_dataFields.PROJECT">
        <div v-if="task.project" class="table-cell-content__breakable-long-text">
          <AppActionText
            :to="{
              name: $routeNames.PROJECT.VIEW,
              params: { projectId: task?.project?.id },
            }"
          >
            {{ task.project.name }}
          </AppActionText>
        </div>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.BELONGS_TO_FORM_ITEM">
        <div class="table-cell-content__breakable--margin">
          <AppActionText
            v-if="task.formItem"
            :to="{
              name: $routeNames.FORM_VIEW.ROOT,
              params: { formId: task.formItem.formId },
              query: { formItemId: task.formItem.id },
            }"
          >
            {{ task.formItem.name }}
          </AppActionText>
        </div>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.START_DATE">
        <template v-if="task.startDate">
          <div class="d-flex align-center">
            <div>{{ formatDate(task.startDate) }}</div>
          </div>
        </template>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.DUE_DATE">
        <template v-if="task.dueDate">
          <div class="d-flex align-center">
            <div>{{ formatDate(task.dueDate) }}</div>
            <div v-if="repeat">
              <AppRepeatIcon small style="padding-bottom: 2px" class="ml-3" />
            </div>
          </div>
        </template>
      </template>
      <v-btn
        v-if="header.value == filtersMixins_dataFields.MENU"
        icon
        @click="dataTableItemMixins_onEllipsisClick"
        ref="ellipsis"
      >
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import moment from "moment";
import { groupByMixins } from "@/helpers/mixins";
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins, dataTableItemMixins],
  props: {
    task: Object,
    isActiveItem: Boolean,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    headers: Array,
    tableModel: String,
    select: Function,
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
        "list_tr--success": this.isActiveItem,
      };
    },
    isOnProjectLevel() {
      return !!this.projectId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    repeat() {
      return this.task?.repeatOption?.repeat;
    },
    statusId() {
      return this.task?.statusId;
    },
    countItems() {
      const count = this.task.count;
      const MENU = this.isOnProjectLevel
        ? this.$routeNames.PROJECT.TASK.LIST.MENU
        : this.$routeNames.TASK.LIST.MENU;

      if (!count) return [];
      return [
        {
          count: count.comments,
          icon: this.$icons.LIGHT.ROUTE.COMMENT,
          text: this.$tc("itemCounts.comments", count.comments),
          to: {
            name: MENU.COMMENTS,
            params: { taskId: this.task.id },
          },
        },
        {
          count: count.attachments,
          icon: this.$icons.LIGHT.ROUTE.ATTACHMENT,
          text: this.$tc("itemCounts.attachments", count.attachments),
          to: {
            name: MENU.STORAGE,
            params: { taskId: this.task.id, folderId: this.task.folderId },
          },
        },
        {
          count: count.forms,
          icon: this.$icons.LIGHT.ROUTE.FORM,
          text: this.$tc("itemCounts.forms", count.forms),
          to: {
            name: MENU.FORMS,
            params: { taskId: this.task.id },
          },
        },
        {
          count: count.productUsages,
          icon: this.$icons.LIGHT.ROUTE.PRODUCT,
          text: this.$tc("itemCounts.products", count.productUsages),
          to: {
            name: MENU.PRODUCT_USAGE,
            params: { taskId: this.task.id },
          },
        },
      ];
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        task: this.task,
        ...params,
      });
    },
    onCountChipClick(route) {
      const isSameTask = this.$route.params.taskId === route.params.taskId;

      if (this.isUnderRoute(route.name) && isSameTask) {
        this.$router.replace({
          name: this.isOnProjectLevel
            ? this.$routeNames.PROJECT.TASK.ROOT
            : this.$routeNames.TASK.ROOT,
        });
      } else {
        this.$router.replace({
          name: route.name,
          params: route.params,
        });
      }
      this.$emit("filters:close");
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    onGoToTaskClick() {
      this.$emit("name:click", { task: this.task });
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    handleInteractableClick({ from, header }) {
      if (!header.interactable) return;

      if (header.value === this.filtersMixins_dataFields.NAME) {
        this.onGoToTaskClick();
        return;
      }

      this.$emit("cell:click", {
        e: {
          from,
          items: [this.task],
        },
        header,
      });
    },
  },
};
</script>

<style>
.v-small-dialog__activator__content {
  float: left;
}
</style>
