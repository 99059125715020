<template>
  <tr
    class="list_tr list_tr-height"
    :class="[rowStateClasses, textColor]"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="timeEntry"
      @click="handleInteractableClick"
    >
      <template v-if="header.value === filtersMixins_dataFields.STATUS">
        <AppTimeEntryStatusIcon
          ref="statusIcon"
          :status="timeEntry.status"
          :loading="timeEntry?.isUpdatingStatus"
        />
      </template>

      <template v-if="header.value === filtersMixins_dataFields.DATE">
        <div class="text-no-wrap">
          {{ new Date(timeEntry.date) | dateFormat("DD.MM.YYYY") }}
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.USER">
        <AppUserAvatar :user="timeEntry.user" show-name :truncate="20" />
      </template>

      <template v-if="header.value === filtersMixins_dataFields.COMMENT">
        <div class="table-cell-content__breakable-long-text">
          <span class="d-inline-block">{{ timeEntry.comment }}</span>
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.IMAGE_IDS">
        <v-btn v-if="imageIds.length > 0" icon @click.stop="onImagePreviewClick">
          <v-icon>{{ $icons.LIGHT.COMMON.IMAGE }}</v-icon>
        </v-btn>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.PROJECT">
        <div v-if="projectId" class="table-cell-content__breakable-long-text">
          <AppActionText
            :textColor="textColor"
            :to="{ name: $routeNames.PROJECT.VIEW, params: { projectId } }"
          >
            {{ projectName }}
          </AppActionText>
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.TASK">
        <div v-if="taskId" class="table-cell-content__breakable-long-text">
          <AppActionText
            :to="{ name: $routeNames.TASK_VIEW.ROOT, params: { taskId } }"
            :textColor="textColor"
          >
            {{ taskName }}
          </AppActionText>
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.TRANSFERRED">
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :color="timeEntry.transferred ? 'success' : ''"
              :style="timeEntry.transferred ? '' : 'opacity: 40%'"
            >
              {{
                timeEntry.transferred
                  ? $icons.SOLID.COMMON.CLOCK
                  : $icons.LIGHT.COMMON.CLOCK
              }}
            </v-icon>
          </template>
          <div>
            {{
              $t(
                `timeRegistration.accounting.${
                  timeEntry.transferred ? "inAccounting" : "notInAccounting"
                }`,
              )
            }}
          </div>
        </AppDefaultTooltip>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.HOUR_TYPE">
        <div class="table-cell-content__breakable--margin">
          {{ timeEntry.hourType.name }}
          <div class="text-caption" v-if="timeEntry.accountingProductId">
            {{ timeEntry.accountingProduct?.name }}
          </div>
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.TIME">
        <div
          v-if="timeEntry.fromTime && timeEntry.toTime"
          :class="durationTextColor"
          class="text-no-wrap"
        >
          {{ timeEntry.fromTime }} - {{ timeEntry.toTime }}
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.DURATION">
        <div :class="durationTextColor">
          {{ timeMixins_formatHoursShort(timeEntry.duration) }}
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.BREAK_DURATION">
        <div :class="durationTextColor">
          {{ timeMixins_formatHoursShort(timeEntry.breakDuration) }}
        </div>
      </template>

      <template v-if="header.value === filtersMixins_dataFields.MENU">
        <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { groupByMixins, timeMixins, dataTableItemMixins } from "@/helpers/mixins";
export default {
  mixins: [timeMixins, groupByMixins, dataTableItemMixins],
  props: {
    index: Number,
    timeEntry: Object,
    isSelected: Boolean,
    select: Function,
    headers: Array,
    tableModel: String,
    isContextMenuActiveItem: Boolean,
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
        "list_tr--error": this.isDeclined,
        "list_tr--warning": this.timeEntry.isExtra,
      };
    },
    projectId() {
      return this.timeEntry?.project?.id;
    },
    taskId() {
      return this.timeEntry?.task?.id;
    },
    isDeclined() {
      return this.timeEntry.status === this.$constant.TIME_ENTRY_STATUS.DECLINED;
    },
    durationTextColor() {
      if (this.isDeclined) return "error--text";
      return "subtitle--text";
    },
    textColor() {
      if (this.isDeclined) return "error--text";
      return "";
    },
    projectName() {
      return this.timeEntry.project?.name;
    },
    taskName() {
      return this.timeEntry.task?.name;
    },
    imageIds() {
      return this.timeEntry.imageIds || [];
    },
  },
  methods: {
    handleInteractableClick({ from, header }) {
      if (!header.interactable) return;

      this.$emit("cell:click", {
        e: {
          from,
          items: [this.timeEntry],
        },
        header,
      });
    },

    openMenu(params) {
      this.$emit("menu:click", {
        timeEntry: this.timeEntry,
        ...params,
      });
    },
    onImagePreviewClick() {
      this.$emit("imagePreview:click", { timeEntry: this.timeEntry });
    },
  },
};
</script>
