<template>
  <div class="d-flex align-center">
    <AppColorChip color="primary--text">
      <AppTextTruncateValue
        :text="title"
        :class="{ 'mr-2': isUpdating && !!title }"
        :truncate="20"
      />
      <template v-slot:append-item v-if="isUpdating">
        <v-icon :class="{ 'py-1': !title }" color="primary" small>
          {{ $icons.SOLID.ACTION.LOADING }}
        </v-icon>
      </template>
    </AppColorChip>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isUpdating: Boolean,
  },
};
</script>
