<template>
  <v-btn icon small @click="onCopy">
    <v-icon small>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
  },
  data() {
    return {
      showCheckIcon: false,
    };
  },
  computed: {
    icon() {
      const icons = this.$icons.REGULAR.ACTION;
      return this.showCheckIcon ? icons.ACCEPT : icons.COPY;
    },
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.text);
    },
    onCopy() {
      this.showCheckIcon = true;
      this.copyText();
      setTimeout(() => {
        this.showCheckIcon = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
