<template>
  <AppDefaultTooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="btn"
        depressed
        :small="!!showName"
        :icon="!showName"
        @click.stop="onClick"
        class="text-transform-none"
        v-bind="attrs"
        v-on="on"
      >
        <AppCustomStatusIcon
          :model="model"
          :statusId="statusId"
          :loading="loading"
          :showTooltip="false"
        />
        <div v-if="showName" class="pl-2 text-subtitle-2">
          {{ name }}
        </div>
      </v-btn>
    </template>
    {{ name }}
  </AppDefaultTooltip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    model: String,
    statusId: String,
    loading: Boolean,
    disabled: Boolean,
    showName: Boolean,
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
    }),
    status() {
      return this.getStatusByModelAndId({ model: this.model, id: this.statusId });
    },
    name() {
      if (!this.status) {
        return this.$t("statuses.noStatus");
      }
      return this.status?.name;
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit("click", { from: this.$refs.btn.$el });
    },
  },
};
</script>
