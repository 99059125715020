<template>
  <div v-if="!computedHidden">
    <AppMenuItem
      block
      v-on="listenersWithoutClick"
      @click="handleClickWrapper"
      :planCode="planCode"
      :showStar="computedShowStar"
      :text="computedText"
      :icon="computedIcon"
      :color="computedColor"
      :disabled="computedDisabled"
      :small="small"
      :backgroundColor="dark ? 'ui-background-black' : 'ui-background'"
      :angleRight="computedHasSubMenu"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    id: String,
    small: { type: Boolean, default: false },
    hidden: [Boolean, Function],
    planCode: String,
    showStar: [Boolean, Function],
    labelKey: [String, Object, Function],
    icon: [String, Object],
    color: [String, Object],
    disabled: [Boolean, Function],
    dark: Boolean,
    selectedItems: Array,
    actionType: {
      type: String,
      default: "SINGLE",
      validator: (value) => ["SINGLE", "BULK"].includes(value),
    },
    hasSubMenu: Boolean,
    isMoreAction: Boolean,
  },
  computed: {
    computedHidden() {
      const isHidden =
        typeof this.hidden === "function"
          ? this.hidden({ items: this.selectedItems })
          : this.hidden;

      return isHidden;
    },
    computedShowStar() {
      return typeof this.showStar === "function"
        ? this.showStar({ items: this.selectedItems })
        : this.showStar;
    },
    computedDisabled() {
      return typeof this.disabled === "function"
        ? this.disabled({ items: this.selectedItems })
        : this.disabled;
    },
    computedColor() {
      if (_.isEmpty(this.color)) {
        return this.dark ? "dark-background-text" : "text";
      }
      if (typeof this.color === "object") {
        return this.color[this.dark ? "dark" : "light"] || this.color[this.actionType];
      }
      return this.color;
    },
    computedText() {
      const labelKey = this.getLabelKey();
      const text = this.$t(labelKey);
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    computedIcon() {
      if (typeof this.icon === "object") {
        return this.icon[this.actionType] || this.icon.SINGLE;
      }
      return this.icon;
    },
    computedHasSubMenu() {
      if (this.actionType !== this.$constant.ACTION_TYPES.SINGLE) return;
      return this.hasSubMenu;
    },
    listenersWithoutClick() {
      return _.omit(this.$listeners, "click");
    },
  },
  methods: {
    handleClick(e) {
      if (!this.$listeners?.click) return;

      const event = {
        items: this.selectedItems,
        from: e.element,
        nudge:
          this.actionType === this.$constant.ACTION_TYPES.BULK && !this.isMoreAction
            ? 8
            : 0,
        hasSubMenu: this.computedHasSubMenu,
      };

      this.$listeners.click(event);
    },
    preventDefault(e) {
      e.pointerEvent.stopPropagation();
      e.pointerEvent.preventDefault();
    },
    handleClickWrapper(e) {
      if (this.computedHasSubMenu) {
        this.preventDefault(e);
      }
      this.handleClick(e);
    },
    getLabelKey() {
      // Case 1: Different text for bulk vs single selection
      // Example: { SINGLE: 'delete.one', BULK: 'delete.many' }
      if (typeof this.labelKey === "object") {
        return this.labelKey[this.actionType];
      }

      // Case 2: Dynamic text based on item properties
      // Example: ({ isSent }) => isSent ? 'sign.showSignOverview' : 'sign.sendToSign'
      if (typeof this.labelKey === "function") {
        const params = this.$attrs.labelKeyParams;
        const labelKeyParams =
          typeof params === "function" ? params({ items: this.selectedItems }) : params;

        return this.labelKey(labelKeyParams);
      }

      // Case 3: Static text that never changes
      // Example: 'menu.download'
      return this.labelKey;

      //This functionality can be extended to the other methods as well if needed
    },
  },
};
</script>
