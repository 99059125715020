<template>
  <div class="d-flex align-center justify-center flex-grow-1">
    <div v-if="loading">
      <v-icon color="primary" size="40" class="mr-2">{{
        $icons.SOLID.ACTION.LOADING
      }}</v-icon>
    </div>
    <v-card
      v-else
      class="pa-8 text-center d-flex flex-column align-center justify-center"
    >
      <v-img
        :src="require('@/assets/confused_gripr_guy.svg')"
        alt="Confused guy"
        max-width="200"
      />
      <div class="text-h6 mt-2">
        {{ $t("common.emptyState.genericTitle") }}
      </div>
      <div class="text-body-2 mt-2">
        {{ subTitle }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    subTitle: String,
    loading: Boolean,
  },
};
</script>
