<template>
  <div>
    <AppCorporateManagementDetailsSection
      :title="t.dialog('sections.details.companyTitle')"
      :fields="companyFields"
      :item="item"
      :translation-fn="t.fields"
      class="mb-6"
    />
    <AppCorporateManagementDetailsSection
      :title="t.dialog('sections.details.contactTitle')"
      :fields="contactFields"
      :item="item"
      :translation-fn="t.fields"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    t() {
      return {
        fields: (key) =>
          this.$t(`settings.corporateManagement.fields.${key.split(".").join(".")}`),
        dialog: (key) =>
          this.$t(
            `settings.corporateManagement.detailsDialog.${key.split(".").join(".")}`,
          ),
      };
    },
  },
  data() {
    return {
      companyFields: [
        { key: "name", value: "companyName", showCopyBtn: true },
        { key: "orgNr", value: "orgNr", showCopyBtn: true },
        { key: "industry", value: "industries", formatter: "industries" },
        {
          key: "employeeCount",
          value: "employeeCount",
          formatter: (val) => (val ? `${val} employees` : "-"),
        },
        { key: "plan", value: "plan.name" },
        { key: "connectedAt", value: "connectedAt", formatter: "date" },
        { key: "status", value: "status", formatter: "statusText" },
      ],
      contactFields: [
        { key: "owner", value: "owner.name" },
        { key: "role", value: "owner.workTitleLabel.name" },
        { key: "email", value: "owner.email", showCopyBtn: true },
        { key: "phone", value: "owner.phone" },
      ],
    };
  },
  methods: {
    async onRequestAccess() {
      await this.$store.dispatch("corporateManagement/requestAccess", {
        companyId: this.item.id,
      });
    },
  },
};
</script>
