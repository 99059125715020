import { render, staticRenderFns } from "./AppActionText.vue?vue&type=template&id=f235c222"
import script from "./AppActionText.vue?vue&type=script&lang=js"
export * from "./AppActionText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports