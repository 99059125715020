import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  isLoading: false,
  parentDomains: [],
});

const getters = {};

const actions = {
  async getParentDomains({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}parent-domain`)
      .then(({ data }) => {
        const parentDomains = data?.parentDomains || [];
        commit("setParentDomains", parentDomains);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
  async revokeParentDomainConnection({ commit }, { ids }) {
    return axios
      .delete(`${apiUrl}parent-domain/revoke`, { data: { ids } })
      .then(({ data }) => {
        const parentDomains = data?.parentDomains || [];
        commit("setParentDomains", parentDomains);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setParentDomains(state, parentDomains) {
    state.parentDomains = parentDomains;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const parentDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
