<template>
  <div v-if="title || subTitle">
    <div class="text-h4 font-weight-bold primary--text">
      {{ title }}
    </div>
    <div v-if="subTitle" class="pt-3">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },
};
</script>

<style lang="scss" scoped></style>
