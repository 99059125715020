<template>
  <td
    v-ripple="isInteractable"
    :class="cellClasses"
    :ref="cellRef"
    @click.stop="handleClick"
  >
    <div :class="contentClasses">
      <template v-if="isSelectCell">
        <AppDefaultCheckboxIcon :isSelected="isSelected" :disabled="!isSelectable" />
      </template>
      <slot v-else></slot>
    </div>
  </td>
</template>

<script>
export default {
  name: "AppDefaultTableCell",
  props: {
    header: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    select: {
      type: Function,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cellRef() {
      return `header-${this.index}`;
    },
    isSelectCell() {
      return this.header.value === "data-table-select";
    },
    isInteractable() {
      if (this.header.disabled) return false;

      if (this.isSelectCell) {
        return this.isSelectable;
      }

      if (typeof this.header.interactable === "function") {
        return this.header.interactable({ item: this.item });
      }

      return !!this.header.interactable;
    },
    isCompact() {
      return this.header.compact;
    },
    contentAlignment() {
      if (this.header.align === "center") return "justify-center";
      if (this.header.align === "end") return "justify-end";
      return "justify-start";
    },
    contentClasses() {
      return {
        "d-flex": true,
        "align-center": true,
        [this.contentAlignment]: true,
      };
    },
    cellClasses() {
      return {
        "cell-interactable": this.isInteractable,
        "cell-compact": this.isCompact,
        "underline-on-hover": this.header.value === "name" && this.isInteractable,
      };
    },
  },
  methods: {
    handleClick(e) {
      if (!this.isInteractable) return;

      if (this.isSelectCell) {
        this.select(!this.isSelected);
        return;
      }

      this.$emit("click", {
        from: this.$refs[this.cellRef],
        header: this.header,
        item: this.item,
      });
    },
  },
};
</script>
