<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background" :disabled="isConverting">
      <AppProjectTemplatePreviewDialog
        v-model="previewDialog.active"
        :templateId="previewDialog.templateId"
      />
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("templateManagement.form.templatePicker.title") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="localDialog = false">
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="main-height d-flex overflow-y-auto">
        <div class="page-container">
          <div class="pb-4 d-flex align-center">
            <div class="d-flex">
              <AppDefaultTextField
                class="mw-250px mr-2"
                :label="$t('common.search')"
                :append-icon="$icons.LIGHT.ACTION.SEARCH"
                :value="filter.search"
                @input="debounceSearch"
                hide-details
                clearable
                autofocus
                background-color="white"
              />
              <AppDefaultAutocomplete
                v-if="hasParentDomain"
                class="mw-250px mr-2"
                :label="$t('common.itemOrigin')"
                :value="filter.origin"
                @change="filter.origin = $event"
                :items="$constant.ITEM_ORIGIN_MAP"
                background-color="white"
                item-value="value"
                item-text="text"
                hide-details
                multiple
                clearable
                :noDataText="$t('common.noItemOriginsFound')"
              />
              <AppNumSelected
                class="mr-2"
                :selectedCount="selectedTemplates.length"
                @click="selectedTemplates = []"
              />
            </div>
            <v-spacer />
            <v-btn
              class="mr-2"
              color="primary"
              text
              :href="`mailto:${$constant.CONTACT_EMAIL}`"
              target="_blank"
            >
              {{ $t("templateManagement.form.templatePicker.request") }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              :disabled="selectedTemplates.length <= 0"
              @click="addTemplates"
              :loading="isConverting"
            >
              {{ $t("templateManagement.form.templatePicker.addTemplates") }}
            </v-btn>
          </div>

          <AppDataTable
            :noDataText="$t('common.noTemplates')"
            v-model="selectedTemplates"
            :loading="isLoading"
            :headers="defaultHeaders"
            :items="templates"
            show-select
          >
            <template v-slot:item="{ item, headers, isSelected, select }">
              <tr
                class="list_tr list_tr-height"
                :class="item.id ? (isSelected ? 'list_tr--selected' : '') : ''"
                @click="select(!isSelected)"
              >
                <AppDefaultTableCell
                  v-for="(header, index) in headers"
                  :key="index"
                  :header="header"
                  :isSelected="isSelected"
                  :select="select"
                  :index="index"
                  :item="item"
                  @click="handleInteractableClick"
                >
                  <template v-if="header.value === 'name'">
                    <span
                      class="underline-on-hover cursor-pointer"
                      @click.stop="onTemplateNameClick(item)"
                    >
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-if="header.value === 'origin'">
                    <AppItemOriginChip :origin="item.origin" />
                  </template>
                  <template v-if="header.value === 'updatedAt'">
                    {{ new Date(item.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
                  </template>
                </AppDefaultTableCell>
              </tr>
            </template>
          </AppDataTable>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";
import { convertProjectTemplates } from "../../../../services/template/projectLibrary/convertProjectTemplates";
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      selectedTemplates: [],
      isConverting: false,
      filter: {
        search: null,
        origin: [],
        tags: [],
      },
      searchDebounce: null,
      templateId: null,
      previewDialog: {
        active: false,
        templateId: null,
      },
    };
  },
  computed: {
    ...mapState("projectTemplateLibrary", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("auth", {
      hasParentDomain: "hasParentDomain",
    }),
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          text: this.$options.filters.capitalize(this.$t("common.name")),
          value: "name",
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.itemOrigin")),
          value: "origin",
          access: this.hasParentDomain,
        },
        {
          preset: "DATE",
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          interactable: false,
        },
      ]);
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getProjectTemplates(this.filterArgs());
        }
      },
    },
    filter: {
      handler(filter) {
        this.getProjectTemplates(this.filterArgs(filter));
      },
      deep: true,
    },
  },
  methods: {
    handleInteractableClick({ from, header, item }) {
      if (!header.interactable) return;
      if (header.value === "name") {
        this.onTemplateNameClick(item);
      }
    },
    getProjectTemplates(args) {
      this.$store.dispatch("projectTemplateLibrary/getTemplates", args);
    },
    getIndustryNames(template) {
      if (!Array.isArray(template.tags)) return [];
      return template.tags.filter((t) => t.type === "INDUSTRY").map((t) => t.name);
    },
    onTemplateNameClick(template) {
      this.previewDialog.templateId = template.id;
      this.previewDialog.active = true;
    },
    addTemplates() {
      if (this.selectedTemplates.length <= 0) return;
      this.isConverting = true;
      const ids = this.selectedTemplates.map((s) => s.id);
      convertProjectTemplates({ ids })
        .then((templates) => {
          this.$emit("converted", { templates });
          this.localDialog = false;
          this.selectedTemplates = [];
        })
        .finally(() => (this.isConverting = false));
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },
    filterArgs(args) {
      return {
        ...this.filter,
        ...args,
      };
    },
  },
};
</script>
