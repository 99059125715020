<template>
  <div>
    <AppDataTable
      ref="dataTable"
      :loading="isLoading"
      :headers="defaultHeaders"
      :items="storages"
      :noDataText="$t('common.noStorage')"
      :height="dataTableHeight"
      :bulkActionConfigs="bulkActionConfigs"
      :isItemSelectable="isItemSelectable"
      :removeMutationType="removeMutationType"
      :updateMutationType="updateMutationType"
      @input="$emit('input', $event)"
      disable-pagination
      show-select
    >
      <template v-slot:item="{ item, headers, isSelected, isSelectable, select }">
        <AppStorageTableItem
          :storage="item"
          :isSelected="isSelected"
          :isSelectable="isSelectable"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :contextMenuItemsToHide="contextMenuItemsToHide"
          :headers="headers"
          @isSelected:change="select"
          @name:click="onNameClick"
          @menu:click="openContextMenu"
          @cell:click="handleInteractableClick"
        />
      </template>
    </AppDataTable>
    <AppStorageContextMenu
      ref="contextMenu"
      :contextMenuActiveItem="contextMenuActiveItem"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      :contextMenuItemsToHide="contextMenuItemsToHide"
      :actionConfigs="actionConfigs"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";
import { mapGetters } from "vuex";
export default {
  props: {
    storages: Array,
    isLoading: Boolean,
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
    dataTableHeight: String,
    contextMenuItemsToHide: Object,
    storageTypesNotAllowedToSelect: Array,
    actionConfigs: Array,
    removeMutationType: String,
    updateMutationType: String,
  },
  model: {
    prop: "selectedStorages",
    event: "selectedStorages:change",
  },
  data() {
    return {
      contextMenuActiveItem: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isCorporateManagementFeatureEnabled: "isCorporateManagementFeatureEnabled",
      isDomainElevatedUser: "isDomainElevatedUser",
    }),
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
        },
        {
          text: this.$t("fileManagement.list.owner"),
          value: "storageOwner.firstName",
        },
        {
          text: this.$t("common.shareable"),
          value: "isShareableWithChildDomains",
          menuRef: "shareableMenu",
          interactable: ({ item }) => item.type === this.$constant.FILE,
          access: this.isCorporateManagementFeatureEnabled && this.isDomainElevatedUser,
        },
        {
          text: this.$t("fileManagement.list.lastModified"),
          align: "start",
          value: "updatedAt",
          access: true,
        },
        {
          preset: "MENU",
        },
      ]);
    },
    selectableStorages() {
      return this.storages.filter((s) => this.isSelectableStorage(s));
    },
    isAllSelectableStoragesSelected() {
      return (
        this.isStoragesSelected(this.selectableStorages) &&
        !!this.selectableStorages?.length
      );
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
  },
  methods: {
    openOpenWithMenu(e) {
      this.$refs.contextMenu.openOpenWithMenu(e);
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    isStoragesSelected(storages) {
      return storages.every((s) =>
        this.localSelectedStorages.some((file) => file.id === s.id),
      );
    },
    isSelectableStorage(storage) {
      return !this.storageTypesNotAllowedToSelect?.includes(storage.type);
    },
    openContextMenu({ from, storage }) {
      if (!this.isSelectableStorage(storage)) return;

      this.$refs.contextMenu.open({ from });

      this.$nextTick(() => {
        this.contextMenuActiveItem = storage;
      });
    },
    isItemSelectable(item) {
      return !this.storageTypesNotAllowedToSelect?.includes(item.type);
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    clearSelection() {
      this.$refs.dataTable.dataTableBulkActionMixin_clearSelection();
    },
    onNameClick({ storage }) {
      this.$emit("storage:click", { storage });
    },
    handleInteractableClick(e) {
      this.$emit("cell:click", e);
    },
  },
};
</script>
