<template>
  <div>
    <div class="text-subtitle-1 font-weight-bold mb-3">
      {{ t.dialog("sections.analytics.title") }}
    </div>
    <AppCorporateManagementDetailsSection
      :fields="analyticsFields"
      :item="item"
      :translation-fn="t.fields"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    t() {
      return {
        fields: (key) =>
          this.$t(`settings.corporateManagement.fields.${key.split(".").join(".")}`),
        dialog: (key) =>
          this.$t(
            `settings.corporateManagement.detailsDialog.${key.split(".").join(".")}`,
          ),
      };
    },
  },
  data() {
    return {
      analyticsFields: [
        {
          key: "documents",
          value: "analytics.documents",
        },
        {
          key: "images",
          value: "analytics.images",
        },
        {
          key: "videos",
          value: "analytics.videos",
        },
        {
          key: "formTemplates",
          value: "analytics.formTemplates",
        },
        {
          key: "projectTemplates",
          value: "analytics.projectTemplates",
        },
        {
          key: "activeUsers",
          value: "analytics.activeUsers",
        },
      ],
    };
  },
};
</script>
