<template>
  <v-btn
    class="w-100"
    ref="menuItemBtn"
    depressed
    :class="`text-none text-subtitle-2 justify-start ${disabledClass} ${backgroundColorIfSelected}`"
    :color="color"
    :block="block"
    :dark="dark"
    :small="small"
    text
    @click="onClick"
    v-bind="$attrs"
    v-on="listenersWithoutClick"
  >
    <div class="d-flex align-center w-100">
      <slot name="prepend-inner"></slot>
      <i v-if="emoji" :class="['mr-2', 'em', emoji]"></i>
      <template v-if="icon">
        <v-icon
          style="width: 20px !important; margin-right: 17.7777777778px"
          class="w-100"
          :class="textIconSpacing"
          :size="iconSize"
          :color="iconColorComputed"
        >
          {{ icon }}
        </v-icon>
      </template>
      <div class="d-flex flex-column align-start">
        <span :class="`${color}--text`">
          <AppTextTruncateValue :text="text" :truncate="40" />
        </span>
        <div v-if="subText" class="text-caption">{{ subText }}</div>
      </div>
      <v-spacer />
      <AppSubscriptionPlanStar class="pl-2" v-if="showStar" :planCode="planCode" small />
      <v-icon v-if="angleRight" class="ml-2" small :color="iconColorComputed">
        {{ $icons.REGULAR.COMMON.ANGLE_RIGHT }}
      </v-icon>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    subText: String,
    icon: String,
    color: String,
    iconColor: String,
    disabled: Boolean,
    value: undefined,
    isSelected: Boolean,
    showStar: Boolean,
    planCode: String,
    emoji: String,
    block: Boolean,
    backgroundColor: String,
    dark: Boolean,
    small: Boolean,
    angleRight: Boolean,
  },
  computed: {
    iconSize() {
      return this.small ? "small" : "medium";
    },
    iconColorComputed() {
      return this.disabled ? "" : this.iconColor ?? this.color;
    },
    backgroundColorIfSelected() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      }

      return this.isSelected ? "action-button" : "";
    },
    disabledClass() {
      return this.disabled ? "v-card--disabled" : "";
    },
    textIconSpacing() {
      if (!this.text) return "mr-0";

      if (this.small) {
        return "mr-1";
      }

      return "mr-2";
    },
    listenersWithoutClick() {
      return _.omit(this.$listeners, "click");
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", {
        value: this.value,
        pointerEvent: e,
        element: this.$refs.menuItemBtn.$el,
      });
    },
  },
};
</script>
