<template>
  <AppDefaultCombobox
    ref="autocomplete"
    :filter="filter || defaultFilter"
    v-on="listeners"
    v-bind="params"
    :search-input.sync="search"
    :menuProps="{
      maxHeight: 400,
      maxWidth: menuMaxWidth,
    }"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <AppDefaultCheckboxIcon :isSelected="attrs.inputValue" />
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex">
            <slot name="prepend-inner" :item="item"></slot>
            <ModuleAutocompleteItem
              :item="item"
              :isSelected="attrs.inputValue"
              :search="lowerSearch"
              :titleField="titleField"
              :subTitleFields="subTitleFields"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </AppDefaultCombobox>
</template>

<script>
import ModuleAutocompleteItem from "./ModuleAutocompleteItem.vue";
import { getDisplayData } from "../../helpers/util/moduleItem";

export default {
  props: {
    titleField: Object,
    subTitleFields: Array,
    multiple: Boolean,
    filter: Function,
  },
  components: {
    ModuleAutocompleteItem,
  },
  data() {
    return {
      search: null,
      inputWidth: null,
    };
  },
  computed: {
    menuMaxWidth() {
      return this.inputWidth * 1.8 || undefined;
    },
    lowerSearch() {
      return this.search?.toLowerCase() || "";
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },

  methods: {
    getText(field) {
      const { text } = getDisplayData(this.item, field);
      return text;
    },
    defaultFilter(item) {
      return item?.searchStr.includes(this.lowerSearch);
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const observer = new MutationObserver(() => {
        const autocompleteEl = this.$refs?.autocomplete?.$el;
        if (autocompleteEl && autocompleteEl.clientWidth > 0) {
          this.inputWidth = autocompleteEl.clientWidth;
          observer.disconnect(); // Stop observing once the width is set
        }
      });

      const config = { attributes: true, childList: true, subtree: true };
      const autocompleteEl = this.$refs?.autocomplete?.$el;
      if (autocompleteEl) {
        observer.observe(autocompleteEl, config);
      }
    });
  },
};
</script>
