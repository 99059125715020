<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="project"
      @click="handleInteractableClick"
    >
      <template v-if="header.value == dataFields.STATUS">
        <AppCustomStatusIcon
          model="project"
          :statusId="statusId"
          :loading="project?.isUpdatingStatus"
        />
      </template>
      <template v-if="header.value === dataFields.NUMBER">
        <div class="text-no-wrap">
          {{ project.projectNumber }}
        </div>
      </template>
      <template v-if="header.value == dataFields.NAME">
        <div class="table-cell-content__breakable-long-text">
          {{ project?.displayName }}
        </div>
      </template>
      <template v-if="header.value == dataFields.CLIENT">
        <div v-if="project.client" class="table-cell-content__breakable-long-text">
          <span class="d-inline-block">
            {{ project.client.name }}
          </span>
        </div>
      </template>
      <template v-if="header.value == dataFields.OWNER">
        <template v-if="project.owner">
          <AppUserAvatar :user="project.owner" show-name :truncate="20" />
        </template>
      </template>
      <template v-if="header.value == dataFields.MEMBERS">
        {{
          $tc(
            "project.list.memberAmount",
            Array.isArray(project.members) ? project.members.length : 0,
          )
        }}
      </template>
      <template v-if="header.value == dataFields.START_DATE">
        {{ startDate }}
      </template>
      <template v-if="header.value == dataFields.MENU">
        <td class="text-end">
          <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
            <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
          </v-btn>
        </td>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  mixins: [dataTableItemMixins],
  props: {
    project: Object,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    select: Function,
    headers: Array,
    dataFields: Object,
  },
  computed: {
    startDate() {
      const date = this.project?.startDate;
      if (!date) return;
      return moment(date).format("DD.MM.YYYY");
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    statusId() {
      return this.project?.statusId;
    },
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        project: this.project,
        ...params,
      });
    },
    onStatusGearClick() {
      this.$emit("gear:click");
    },
    onGoToProjectClick() {
      this.$router.push({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: this.project.id },
      });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { project: this.project, event });
    },
    handleInteractableClick({ from, header }) {
      if (!header.interactable) return;

      if (header.value === this.dataFields.NAME) {
        this.onGoToProjectClick();
        return;
      }

      this.$emit("cell:click", {
        e: {
          from,
          items: [this.project],
        },
        header,
      });
    },
  },
};
</script>
