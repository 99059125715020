import i18n from "@/plugins/i18n";
import { capitalize } from "lodash";

const DEFAULT_HEADER_PROPS = {
  access: true,
  menuRef: null,
  interactable: false,
  compact: false,
  required: false,
  enabled: true,
  sortable: true,
  align: "start",
  disabled: false,
};

export const addDefaultHeaderProps = (config) => {
  return {
    ...DEFAULT_HEADER_PROPS,
    ...config,
  };
};

export const HEADER_PRESETS = {
  SELECT: {
    text: capitalize(i18n.t("common.select")),
    value: "data-table-select",
    compact: true,
    interactable: true,
    align: "center",
  },
  STATUS: {
    align: "center",
    compact: true,
    interactable: true,
    text: capitalize(i18n.t("common.status")),
  },
  MENU: {
    align: "center",
    compact: true,
    sortable: false,
    text: capitalize(i18n.t("common.menu")),
    value: "menu",
  },
  NAME: {
    interactable: true,
    required: true,
    text: capitalize(i18n.t("common.name")),
    value: "name",
  },
  DATE: {
    compact: true,
    interactable: true,
  },
  CONTENT_OVERVIEW: {
    align: "end",
    compact: true,
    sortable: false,
    text: capitalize(i18n.t("common.contentOverview")),
  },
};

export const createTableHeader = (config, preset) => {
  return addDefaultHeaderProps({
    ...HEADER_PRESETS[preset],
    ...config,
  });
};

export const createTableHeaders = (headerConfigs) => {
  return headerConfigs.map(({ preset, ...config }) => createTableHeader(config, preset));
};
