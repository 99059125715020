export const dataTableItemMixins = {
  methods: {
    dataTableItemMixins_onRightClick(pointerEvent) {
      const params = { from: pointerEvent, direction: "bottom" };
      this.openMenu(params);
    },
    dataTableItemMixins_onEllipsisClick(pointerEvent) {
      pointerEvent.stopPropagation();
      //this was required once i put it in a template
      const ellipsis = this.$refs.ellipsis;
      let element;

      if (ellipsis[0]) {
        element = ellipsis[0].$el;
      } else {
        element = ellipsis.$el;
      }

      const params = { from: element, direction: "bottom" };
      this.openMenu(params);
    },
  },
};
