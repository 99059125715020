<template>
  <AppContextMenu ref="menu" height="440" width="300" :closeOnContentClick="false">
    <AppTaskAutocomplete
      @change="onSubmit"
      @created="({ task }) => onSubmit(task.id)"
      isReadyToInitialFetch
      autofocus
      activateOnFocus
      resetOnSubmit
      :maxWidthMultiplier="1"
      :queryParams="queryParams"
      :data="data"
    />
  </AppContextMenu>
</template>

<script>
export default {
  props: {
    queryParams: Object,
    data: Object,
  },
  data() {
    return {};
  },
  methods: {
    onSubmit(taskId) {
      this.$emit("submit", { taskId });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
