import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import nest from "../../../helpers/flatListToTree";

export async function getProjectTemplate({ id }) {
  return axios
    .get(`${apiUrl}template-library/project/${id}`)
    .then(({ data }) => {
      return data ? data.template : null;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getProjectTemplateItems({ id }) {
  return axios
    .get(`${apiUrl}template-library/project/${id}/items`)
    .then(({ data }) => {
      const items = data?.items || [];
      const storageTreeView = nest(items.filter((i) => i.type === "STORAGE"));
      return { items, storageTreeView };
    })
    .catch((error) => {
      throw new Error(error);
    });
}
