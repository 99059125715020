<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background">
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn class="mr-2" icon dark @click="localDialog = false">
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="main-height d-flex overflow-y-auto">
        <div class="page-container">
          <div class="pb-4 d-flex align-center">
            <div class="d-flex">
              <AppDefaultTextField
                class="mw-250px mr-2"
                :label="$t('common.search')"
                :append-icon="$icons.LIGHT.ACTION.SEARCH"
                :value="filter.search"
                @input="debounceSearch"
                hide-details
                autofocus
                background-color="white"
                clearable
              />
              <AppDefaultSelect
                v-if="showCategory"
                class="mw-250px"
                v-model="filter.category"
                :items="categories"
                :loading="isCategoriesLoading"
                :label="$t('common.category')"
                hide-details
                item-text="text"
                item-value="value"
                background-color="white"
              />
              <AppNumSelected
                class="ml-3"
                :selectedCount="newlySelectedTemplatesCount"
                @click="clearNewlySelectedTemplates"
              />
              <v-checkbox
                v-if="!allowDuplicateTemplates"
                class="ma-0 pa-0 pl-3 d-flex align-center"
                :label="$t('common.showAdded')"
                v-model="showImportedTemplates"
                hide-details
              />
            </div>
            <v-spacer />
            <v-btn
              @click="onAddTemplateClick"
              color="primary"
              depressed
              :disabled="selectedTemplates.length <= 0"
              :loading="isSaving"
            >
              {{ saveBtnText || $t("common.addDocumentTemplates") }}
            </v-btn>
          </div>
          <AppDataTable
            v-model="selectedTemplates"
            @input="onSelect"
            :loading="isLoading"
            :headers="defaultHeaders"
            :items="filteredTemplates"
            :noDataText="$t('common.noTemplates')"
            :isItemSelectable="(item) => allowDuplicateTemplates || !item.storage"
            show-select
          >
            <template v-slot:item="{ item, headers, isSelected, select }">
              <tr
                class="list_tr list_tr-height"
                :class="rowStateClasses(item, isSelected)"
                @click="item.storage ? null : select(!isSelected)"
              >
                <AppDefaultTableCell
                  v-for="(header, index) in headers"
                  :key="index"
                  :header="header"
                  :isSelected="isSelected"
                  :isSelectable="allowDuplicateTemplates || !item.storage"
                  :select="select"
                  :index="index"
                  :item="item"
                >
                  <template v-if="header.value === 'name'">
                    <div
                      class="d-flex align-center justify-center rounded background mr-4"
                      :style="{
                        width: `40px`,
                        height: `40px`,
                        minWidth: `40px`,
                      }"
                    >
                      <StorageIcon :storage="item.document || item" />
                    </div>
                    <div class="d-flex align-center">
                      <span>{{ item.name }}</span>
                    </div>
                  </template>
                  <template v-if="header.value === 'category'">
                    <AppColorChip
                      v-if="item.category"
                      :text="
                        $te(`cms.template.document.category.${item.category.name}`)
                          ? $t(`cms.template.document.category.${item.category.name}`)
                          : item.category.name
                      "
                      color="info--text"
                    />
                  </template>
                  <template v-if="header.value === 'updatedAt'">
                    {{ new Date(item.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
                  </template>
                  <template v-if="header.value === 'actions'">
                    <v-btn
                      v-if="showDownload"
                      class="mr-1"
                      icon
                      @click="downloadItem(item)"
                      :loading="item.isDownloading"
                    >
                      <v-icon>{{ $icons.LIGHT.ACTION.DOWNLOAD }}</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="showDelete"
                      icon
                      color="error"
                      @click="onDeleteItem(item)"
                      :disabled="!item.storage"
                      :loading="item.isDeleting"
                    >
                      <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
                    </v-btn>
                  </template>
                </AppDefaultTableCell>
              </tr>
            </template>
          </AppDataTable>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import StorageIcon from "@/components/storage/thumbnail/StorageIcon.vue";
import { createTableHeaders } from "@/helpers/util";

export default {
  components: {
    StorageIcon,
  },
  props: {
    title: String,
    saveBtnText: String,
    dialog: Boolean,
    group: String,
    templates: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    isSaving: Boolean,
    categories: {
      type: Array,
      default: () => [],
    },
    isCategoriesLoading: Boolean,
    allowDuplicateTemplates: Boolean,
    showDelete: Boolean,
    showDownload: Boolean,
    showCategory: Boolean,
  },

  data() {
    return {
      filter: {
        search: null,
        category: null,
      },
      searchDebounce: null,
      selectedTemplates: [],
      showImportedTemplates: false,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          text: this.$options.filters.capitalize(this.$t("common.name")),
          interactable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.category")),
          compact: true,
          value: "category.name",
          access: this.showCategory,
        },
        {
          preset: "DATE",
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          interactable: false,
        },
        {
          text: this.$t("common.actions"),
          value: "actions",
          compact: true,
          sortable: false,
          align: "center",
          access: this.showDelete || this.showDownload,
        },
      ]);
    },

    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },

    newlySelectedTemplatesCount() {
      return this.selectedTemplates.filter((x) => !x.storage).length;
    },

    filteredTemplates() {
      if (this.allowDuplicateTemplates) {
        return this.templates;
      }

      return this.showImportedTemplates
        ? this.templates
        : this.templates.filter((x) => !x.storage);
    },
  },

  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.selectedTemplates = [];
          this.$emit("init");
        }
      },
    },
    filter: {
      handler(filter) {
        this.$emit("filter-change", this.filterArgs(filter));
      },
      deep: true,
    },
  },

  methods: {
    onSelect(newValue) {
      this.selectedTemplates = newValue;
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },

    clearNewlySelectedTemplates() {
      this.selectedTemplates = this.selectedTemplates.filter((x) => x.storage);
    },

    getIconColor(item, isSelected) {
      if (!item.id) return null;
      if (isSelected) {
        return item.storage ? "info" : "success";
      }
    },

    rowStateClasses(item, isSelected) {
      if (!item.id) return null;
      if (item.storage) {
        return "list_tr--success";
      } else if (isSelected) {
        return "list_tr--selected";
      }
    },

    downloadItem(item) {
      this.$emit("download-item", item);
    },

    onDeleteItem(item) {
      this.$emit("delete-item", item);
    },

    onAddTemplateClick() {
      this.$emit("add-template", this.selectedTemplates);
    },

    filterArgs(args) {
      return {
        ...this.filter,
        ...args,
        group: this.group,
      };
    },
  },
};
</script>
