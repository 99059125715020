<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background">
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("templateManagement.project.preview.mainToolbar.title") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="localDialog = false">
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex main-height">
        <div class="w-100 main-height overflow-y-auto">
          <AppProjectTemplatePreview
            class="mw-1100px"
            style="min-width: 650px"
            :showHSE="isHseFeatureEnabled"
            :showQA="isQaFeatureEnabled"
            :template="template"
            :templateItems="templateItems"
            :isLoading="isLoading"
            :storageTreeView="storageTreeView"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getProjectTemplate,
  getProjectTemplateItems,
} from "../../../../services/template/projectLibrary/previewProjectTemplate";

export default {
  props: {
    dialog: Boolean,
    templateId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      template: null,
      isTemplateLoading: false,
      templateItems: [],
      storageTreeView: [],
      isItemsLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isHseFeatureEnabled: "isHseFeatureEnabled",
      isQaFeatureEnabled: "isQaFeatureEnabled",
    }),
    isLoading() {
      return this.isTemplateLoading || this.isItemsLoading;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getTemplate();
        }
      },
    },
  },
  methods: {
    getTemplate() {
      this.isTemplateLoading = true;
      this.isItemsLoading = true;
      getProjectTemplate({ id: this.templateId })
        .then((template) => {
          this.template = template;
        })
        .finally(() => (this.isTemplateLoading = false));

      getProjectTemplateItems({ id: this.templateId })
        .then(({ items, storageTreeView }) => {
          this.storageTreeView = storageTreeView;
          this.templateItems = items;
        })
        .finally(() => (this.isItemsLoading = false));
    },
  },
};
</script>
