<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteTask.active"
      :title="
        $tc('taskManagement.deleteConfirmation.title', dialog.deleteTask.item?.length)
      "
      skip-validation
      :item="dialog.deleteTask.item"
      @delete="taskDelete"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="tasks"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noTasks')"
      :getAllItems="getAllItems"
      :bulkActionConfigs="bulkActionConfigs"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
      removeMutationType="task/removeTask"
      updateMutationType="task/updateTask"
      show-select
    >
      <template
        v-slot:group.header="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="cell-interactable d-flex align-center justify-center"
          v-ripple
        >
          <AppDefaultCheckboxIcon
            :isIndeterminate="isGroupIndeterminate"
            :isSelected="isGroupSelected"
          />
        </td>
        <td :colspan="headers.length - 6">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="5"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppTaskTableItem
          :task="item"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isActiveItem="isActiveTask(item)"
          @cell:click="handleInteractableClick"
          @name:click="onTaskDetailsClick({ items: [item] })"
          @menu:click="openContextMenu"
          @filters:close="$emit('filters:close')"
          @status:click="onStatusClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppCustomStatusPickerMenu ref="statusMenu" @submit="onTasksUpdate" model="task" />
    <AppUserPickerMenu
      ref="responsibleMenu"
      @submit="(id) => onTasksUpdate({ ownerId: id })"
      model="task"
    />
    <AppUserPickerMenu
      ref="membersMenu"
      @submit="(ids) => onTasksUpdate({ memberIds: ids })"
      model="task"
      multiple
    />
    <AppProjectPickerMenu ref="projectMenu" @submit="onTasksUpdate" model="task" />
    <AppDatePickerMenu
      ref="startDateMenu"
      @submit="({ date }) => onTasksUpdate({ startDate: date })"
      model="task"
    />
    <AppDatePickerMenu
      ref="dueDateMenu"
      @submit="({ date }) => onTasksUpdate({ dueDate: date })"
      model="task"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";
import { getAllTasks } from "@/services/task/allTasks";

export default {
  mixins: [groupByMixins],
  props: {
    tasks: Array,
    isLoading: Boolean,
    taskId: String,
    tableModel: String,
    serverItemsLength: Number,
    selectedTasks: Array,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteTask: {
          active: false,
          item: [],
        },
      },
      contextMenuActiveItem: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.filtersMixins_dataFields.STATUS,
          menuRef: "statusMenu",
        },
        {
          preset: "NAME",
          text: this.$t("taskManagement.list.taskName"),
          value: this.filtersMixins_dataFields.NAME,
        },
        {
          preset: "CONTENT_OVERVIEW",
          value: this.filtersMixins_dataFields.COUNT,
        },
        {
          text: this.$t("taskManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          interactable: true,
          menuRef: "responsibleMenu",
        },
        {
          text: this.$t("taskManagement.list.participants"),
          value: this.filtersMixins_dataFields.COLLABORATORS,
          interactable: true,
          menuRef: "membersMenu",
        },
        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          access: !this.projectId,
          interactable: true,
          menuRef: "projectMenu",
        },
        {
          text: this.$t("taskManagement.list.belongsToFormItem"),
          value: this.filtersMixins_dataFields.BELONGS_TO_FORM_ITEM,
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.startDate"),
          value: this.filtersMixins_dataFields.START_DATE,
          menuRef: "startDateMenu",
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          menuRef: "dueDateMenu",
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
    activeTask() {
      return this.tasks.find((t) => t.id === this.taskId);
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const {
        DELETE,
        COPY_URL,
        OVERVIEW,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_MEMBERS,
        EDIT_START_DATE,
        EDIT_DUE_DATE,
        EDIT_PROJECT,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: OVERVIEW,
          on: {
            click: this.onTaskDetailsClick,
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("statusMenu"),
          },
        },
        {
          key: COPY_URL,
          on: {
            click: this.onCopyUrl,
          },
        },

        {
          key: EDIT_RESPONSIBLE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("responsibleMenu"),
          },
        },
        {
          key: EDIT_MEMBERS,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("membersMenu"),
          },
        },
        {
          key: EDIT_START_DATE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("startDateMenu"),
          },
        },
        {
          key: EDIT_DUE_DATE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("dueDateMenu"),
          },
        },
        {
          key: EDIT_PROJECT,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: _openEditMenu("projectMenu"),
          },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: this.onTasksDelete,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.TASK_ACTIONS,
      });

      return config;
    },
  },
  methods: {
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onCopyUrl({ items }) {
      const route = {
        name: this.$routeNames.TASK_VIEW.ROOT,
        params: { taskId: items[0].id },
      };

      const taskUrl = this.$router.resolve(route).href;

      const fullPath = `${window.location.origin}${taskUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    canEditTask(item) {
      return item?.permissions?.canEdit;
    },
    canEditItems({ items }) {
      return items?.every((task) => this.canEditTask(task));
    },
    async getAllItems() {
      return await getAllTasks({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.projectId ? { projectId: this.projectId } : {}),
        },
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    isActiveTask(task) {
      const isTaskIdInParams = task?.id === this.activeTask?.id;
      return isTaskIdInParams;
    },
    onStatusChange({ statusId }) {
      const body = { statusId };
      this.onTaskUpdate({ body, task: this.itemsToEdit[0] });
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    openContextMenu({ from, task, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = task;
      });
    },
    onTaskDetailsClick({ items }) {
      this.$emit("task:details", { item: items[0] });
    },
    onTaskUpdate({ body, task }) {
      this.$emit("task:update", { data: body, item: task });
    },
    onTasksUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("tasks:update", { body });
    },
    onTasksDelete({ items }) {
      this.dialog.deleteTask.item = items;
      this.dialog.deleteTask.active = true;
    },
    taskDelete() {
      this.$emit("tasks:delete", { tasks: this.dialog.deleteTask.item });
    },
  },
};
</script>
