import { getInstance } from "./index";
import store from "../store";
import {
  currentRootRouteName,
  isHighestPriorityRoleContact,
  isValidRootRouteName,
  storeUtmParametersInLocalStorage,
} from "../helpers/util";
import { ROUTE_NAMES } from "@/helpers/routeNames";

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }
    const args = { appState: { targetUrl: to.fullPath } };
    if (to.query.ref) {
      args.ref = to.query.ref;
    }
    // Otherwise, log in
    authService.loginWithRedirect(args);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const userGuard = (permissions) => {
  return async (to, from, next) => {
    const user = await getUser(next);

    if (isAuth0Login(to)) {
      return next();
    }

    if (user.hasNoDomainsAndIsInvited) {
      return next({ name: ROUTE_NAMES.GET_STARTED.LANDING });
    }
    if (!user.domains.length) {
      return next({ name: ROUTE_NAMES.GET_STARTED.NEW_WORKSPACE });
    }

    if (!permissions) {
      return next();
    }
    if (permissions.admin && user.currentDomain.isAdmin) {
      return next();
    }
    if (permissions.owner && user.currentDomain.isOwner) {
      return next();
    }
    if (permissions.hrAdmin && user.currentDomain.isHrAdmin) {
      return next();
    }

    return next(from.path);
  };
};

export const contactUserRedirectGuard = async (to, from, next) => {
  if (isHighestPriorityRoleContact()) {
    return next({ name: "notFound" });
  }
  return next();
};

export const rootRouteRedirectGuard = (to, from, next) => {
  const user = store?.state?.auth?.user;
  const routeName = currentRootRouteName(user) || ROUTE_NAMES.DASHBOARD.ROOT;

  if (isAuth0Login(to)) {
    return next();
  }

  if (!!isValidRootRouteName({ user, routeName })) {
    return next({ name: routeName });
  }

  return next();
};

export const redirectToLoginGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    const email = to?.query?.email;
    authService.logout();
    setTimeout(() => {
      authService.loginWithRedirect({
        appState: { targetUrl: "/" },
        authorizationParams: {
          ...(email && {
            login_hint: email,
          }),
        },
      });
    }, 100);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const redirectToSignupGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    const email = to?.query?.email;
    const returnPath = to?.query?.returnPath
      ? decodeURIComponent(to.query.returnPath)
      : undefined;
    const campaignCode = to?.params?.campaignCode;
    if (campaignCode) {
      localStorage.setItem("signupCampaignCode", campaignCode);
    }
    storeUtmParametersInLocalStorage(to?.query);

    if (authService.isAuthenticated) {
      authService.logout();
    }
    setTimeout(() => {
      authService.loginWithRedirect({
        appState: { targetUrl: returnPath || "/" },
        authorizationParams: {
          screen_hint: "signup",
          ...(email && {
            login_hint: email,
            connection: "Username-Password-Authentication",
          }),
        },
      });
    }, 100);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

const getUser = async (next) => {
  let { user } = store?.state?.auth;
  if (!user) {
    try {
      user = await store.dispatch("auth/getUser");
    } catch (error) {
      next({ name: "error" });
      throw new Error(error);
    }
  }
  return user;
};

// Skip guard during Auth0 login
const isAuth0Login = (to) => {
  const { code, state } = to.query;
  return !!code && !!state;
};
