var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"list_tr list_tr-height",class:_vm.rowStateClasses,on:{"contextmenu":_vm.dataTableItemMixins_onRightClick,"click":function($event){return _vm.select(!_vm.isSelected)}}},_vm._l((_vm.headers),function(header,index){return _c('AppDefaultTableCell',{key:index,attrs:{"header":header,"isSelected":_vm.isSelected,"select":_vm.select,"index":index,"item":_vm.task},on:{"click":_vm.handleInteractableClick}},[(header.value == _vm.filtersMixins_dataFields.STATUS)?[_c('AppCustomStatusIcon',{attrs:{"model":"task","statusId":_vm.statusId,"loading":_vm.task?.isUpdatingStatus}})]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.NAME)?[_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")])]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.COUNT)?[_c('div',{staticClass:"d-flex justify-end"},_vm._l((_vm.countItems),function(countData,index){return (countData.count)?_c('AppCountChip',{key:index,class:index === 0 && countData.count ? '' : 'ml-2',attrs:{"count":countData.count > 99 ? '99+' : countData.count,"icon":countData.icon,"text":countData.text},on:{"click":function($event){return _vm.onCountChipClick(countData.to)}}}):_vm._e()}),1)]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.RESPONSIBLE)?[(_vm.task.member)?[_c('AppUserAvatar',{attrs:{"user":_vm.task.member,"show-name":"","truncate":22}})]:_vm._e()]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.COLLABORATORS)?[_vm._v(" "+_vm._s(_vm.$tc( "taskManagement.list.participantAmount", Array.isArray(_vm.task.collaborators) ? _vm.task.collaborators.length : 0, ))+" ")]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.PROJECT)?[(_vm.task.project)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
            name: _vm.$routeNames.PROJECT.VIEW,
            params: { projectId: _vm.task?.project?.id },
          }}},[_vm._v(" "+_vm._s(_vm.task.project.name)+" ")])],1):_vm._e()]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.BELONGS_TO_FORM_ITEM)?[_c('div',{staticClass:"table-cell-content__breakable--margin"},[(_vm.task.formItem)?_c('AppActionText',{attrs:{"to":{
            name: _vm.$routeNames.FORM_VIEW.ROOT,
            params: { formId: _vm.task.formItem.formId },
            query: { formItemId: _vm.task.formItem.id },
          }}},[_vm._v(" "+_vm._s(_vm.task.formItem.name)+" ")]):_vm._e()],1)]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.START_DATE)?[(_vm.task.startDate)?[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.task.startDate)))])])]:_vm._e()]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.DUE_DATE)?[(_vm.task.dueDate)?[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.task.dueDate)))]),(_vm.repeat)?_c('div',[_c('AppRepeatIcon',{staticClass:"ml-3",staticStyle:{"padding-bottom":"2px"},attrs:{"small":""}})],1):_vm._e()])]:_vm._e()]:_vm._e(),(header.value == _vm.filtersMixins_dataFields.MENU)?_c('v-btn',{ref:"ellipsis",refInFor:true,attrs:{"icon":""},on:{"click":_vm.dataTableItemMixins_onEllipsisClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.REGULAR.ACTION.MENU))])],1):_vm._e()],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }