<template>
  <v-list-item
    style="height: 40px !important"
    class="border-a rounded ui-background--background list_tr cursor-grab draggable-item"
    dense
  >
    <v-list-item-icon>
      <v-icon class="grey--text">{{ $icons.SOLID.ACTION.DRAG }}</v-icon>
    </v-list-item-icon>
    <span class="user-select-none">{{ header?.text }}</span>
    <v-spacer />
    <v-list-item-icon>
      <v-btn
        v-if="!header.required"
        icon
        small
        @click="$emit('click')"
        class="block-draggable"
      >
        <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  props: {
    header: Object,
  },
};
</script>
