export const FEATURE_NAMES = {
  //   TASK_MANAGEMENT: "TASK_MANAGEMENT",
  COMMENT: "COMMENT",
  HISTORICAL_LOG: "HISTORICAL_LOG",
  LOOM_RECORDING: "LOOM_RECORDING",
  FILE_MANAGEMENT: "FILE_MANAGEMENT",
  FORM_MANAGEMENT: "FORM_MANAGEMENT",
  HSE: "HSE",
  QA: "QA",
  TIME_ENTRY: "TIME_ENTRY",
  TIME_ENTRY_ADVANCED: "TIME_ENTRY_ADVANCED",
  CORPORATE_MANAGEMENT: "CORPORATE_MANAGEMENT",
};

export const FEATURE_STATUSES = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};
