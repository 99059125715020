<template>
  <div class="ui-background--background rounded-lg border-a">
    <AppWideCardContent
      :title="title"
      :subTitle="subTitle"
      :icon="icon"
      :faIcon="faIcon"
      :backgroundColor="backgroundColor"
      :search="search"
      :stonlyGuideId="stonlyGuideId"
    >
      <slot></slot>
      <template v-slot:bottom>
        <slot name="bottom"></slot>
      </template>
      <template v-slot:subtitle>
        <slot name="subtitle"></slot>
      </template>
    </AppWideCardContent>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    icon: String,
    faIcon: String,
    backgroundColor: {
      type: String,
      default: "input-background--background",
    },
    search: String,
    stonlyGuideId: String,
  },
};
</script>
