<template>
  <AppColorChip
    v-if="origin"
    :text="$constant.getItemOriginText(origin)"
    color="info--text"
  />
</template>

<script>
export default {
  props: {
    origin: String,
  },
};
</script>
