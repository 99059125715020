<template>
  <v-icon :color="disabled ? 'grey' : 'primary'">
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  props: {
    isIndeterminate: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.isIndeterminate
        ? this.$icons.SOLID.COMMON.SQUARE_MINUS
        : this.isSelected
        ? this.$icons.SOLID.COMMON.SQUARE_CHECK
        : this.$icons.LIGHT.COMMON.SQUARE;
    },
  },
};
</script>
