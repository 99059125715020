import { mapGetters } from "vuex";

export const timeEntryActionMixins = {
  computed: {
    ...mapGetters("auth", {
      isDomainElevatedUser: "isDomainElevatedUser",
    }),
    timeEntryActionMixins_showSendToAccounting() {
      return (
        this.hasTimeEntriesIntegrationEnabled &&
        this.isDomainElevatedUser &&
        this.planRestrictionsMixins_canIntegrateAccounting
      );
    },
  },
  methods: {
    timeEntryActionMixins_hasMappedHourTypes({ items }) {
      return items.every((te) => !!te.hourType.externalCode);
    },
    timeEntryActionMixins_isNoItemsInAccounting({ items }) {
      return items.every((item) => !item?.transferred);
    },
    timeEntryActionMixins_isAllItemsApproved({ items }) {
      return items.every(
        (te) => te?.status === this.$constant.TIME_ENTRY_STATUS.APPROVED,
      );
    },
    timeEntryActionMixins_isMultipleSelected({ items }) {
      return items.length > 1;
    },
    timeEntryActionMixins_isSomeSelectedItemsApproved({ items }) {
      return items.some((te) => te?.status === this.$constant.TIME_ENTRY_STATUS.APPROVED);
    },
    timeEntryActionMixins_isOwnerOfProjectOfAllItems({ items }) {
      return items.every((te) => te?.project?.ownerId === this.currentUserId);
    },
    timeEntryActionMixins_isEditDisabled({ items }) {
      if (
        this.timeEntryActionMixins_isMultipleSelected({ items }) ||
        this.timeEntryActionMixins_isSomeItemsInAccounting({ items }) ||
        this.timeEntryActionMixins_isSomeSelectedItemsApproved({ items })
      ) {
        return true;
      }
      if (
        this.isDomainElevatedUser ||
        this.timeEntryActionMixins_isOwnerOfProjectOfAllItems({ items }) ||
        this.timeEntryActionMixins_isOwnerOfAllItems({ items })
      ) {
        return false;
      }
      return true;
    },
    timeEntryActionMixins_isOwnerOfAllItems({ items }) {
      return items.every((te) => {
        return te.userId === this.currentUserId;
      });
    },
    timeEntryActionMixins_isDeleteDisabled({ items }) {
      if (this.timeEntryActionMixins_isSomeItemsInAccounting({ items })) {
        return true;
      }
      if (this.isDomainElevatedUser) {
        return false;
      }
      if (this.timeEntryActionMixins_isOwnerOfAllItems({ items })) {
        return false;
      }
      return true;
    },
    timeEntryActionMixins_isSomeItemsInAccounting({ items }) {
      return items.some((te) => !!te?.transferred);
    },
    timeEntryActionMixins_isDeclined({ item }) {
      const isDeclined = item?.status === this.$constant.TIME_ENTRY_STATUS.DECLINED;
      return isDeclined;
    },
    timeEntryActionMixins_isEveryItemInAccounting({ items }) {
      return items.every((item) => item?.transferred);
    },
    timeEntryActionMixins_getActionsByType(type) {
      return getActionsByType(type);
    },
    timeEntryActionMixins_isChangeStatusesDisabled(timeEntries, isMultipleSelected) {
      const statusObject = this.timeEntryActionMixins_isChangeStatusesItemsDisabled(
        timeEntries,
        isMultipleSelected,
      );

      return Object.values(statusObject).every((isDisabled) => isDisabled);
    },
    timeEntryActionMixins_isChangeStatusesItemsDisabled(timeEntries, isMultipleSelected) {
      const isDisabledStatuses = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, action) => {
          result[action.value] = timeEntries.some(
            (timeEntry) =>
              this.timeEntryActionMixins_isChangeStatusItemsDisabled(
                timeEntry,
                isMultipleSelected,
              )[action.value],
          );
          return result;
        },
        {},
      );

      return isDisabledStatuses;
    },
    timeEntryActionMixins_isChangeStatusItemsDisabled(timeEntry, isMultipleSelected) {
      if (!timeEntry) return false;
      const isDisabled = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, statusItem) => {
          result[statusItem.value] = true;
          return result;
        },
        {},
      );

      const setIsDisabled = (value) => {
        for (const statusItem of this.statusMixins_timeEntriesStatusItems) {
          isDisabled[statusItem.value] = value;
        }
      };

      const isProjectOwner = timeEntry.project?.ownerId === this.currentUserId;

      if (this.isDomainElevatedUser) {
        //Enable all for super users
        setIsDisabled(false);
      } else if (isProjectOwner) {
        //Project manager can manage time entries for entries on a project except their own
        const isTimeEntryOwner = this.timeEntryActionMixins_isTimeEntryOwner(timeEntry);
        setIsDisabled(isTimeEntryOwner);
      } else {
        //disable for everyone else
        setIsDisabled(true);
      }

      // Logic for all users regardless of role
      if (isMultipleSelected) {
        isDisabled.DECLINED = true;
      }
      if (timeEntry.transferred) {
        // Entries in accounting cant be edited
        setIsDisabled(true);
      }
      return isDisabled;
    },
    timeEntryActionMixins_isTimeEntryOwner(timeEntry) {
      return timeEntry.userId === this.currentUserId;
    },
  },
};
