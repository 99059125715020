import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  templates: [],
  isLoading: false,
  filter: null,
});

const getters = {};

const actions = {
  async getTemplates({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}template-library/project`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const templates = data?.templates || [];
        commit("setTemplates", templates);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const projectTemplateLibrary = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
