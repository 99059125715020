import i18n from "@/plugins/i18n";

//All origins
const ITEM_ORIGINS = ["GRIPR_CMS", "SHARED_BY_PARENT_DOMAIN", "CUSTOM_CREATED"];

//The origins that are related to the library
const LIBRARY_ORIGINS = ITEM_ORIGINS.filter((origin) => origin !== "CUSTOM_CREATED");

//Map of origins to be used in the UI
export const ITEM_ORIGIN_MAP = LIBRARY_ORIGINS.map((origin) => ({
  value: origin,
  text: i18n.t(`common.itemOrigins.${origin}`),
}));

export const getItemOriginText = (origin) => i18n.t(`common.itemOrigins.${origin}`);
