<template>
  <div class="d-flex justify-end flex-column">
    <AppDefaultAlert :type="type" class="mb-3">
      <div class="d-flex align-center">
        <span>
          {{ title }}
        </span>
        <v-btn
          small
          text
          color="primary"
          class="ml-2"
          :loading="isInviting"
          @click="$emit('reInvite:click', $event)"
        >
          {{ $t("settings.corporateManagement.detailsDialog.reInvite") }}
        </v-btn>
        <v-btn
          v-if="showDelete"
          small
          text
          color="error"
          class="ml-1"
          @click="$emit('delete:click', $event)"
        >
          {{ $t("settings.corporateManagement.detailsDialog.delete") }}
        </v-btn>
      </div>
    </AppDefaultAlert>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    title: String,
    type: String,
    showDelete: Boolean,
  },
  computed: {
    ...mapState("corporateManagement", {
      isInviting: (state) => state.isInviting,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
