<template>
  <v-dialog v-model="localDialog" max-width="1000px" @click.stop.prevent>
    <v-card class="d-flex" height="800px" width="1000px">
      <v-navigation-drawer v-if="localDialog" permanent :min-width="256" :max-width="256">
        <AppDrawerHeader
          class="pa-3 px-6"
          :title="item?.companyName"
          :subTitle="item?.orgNr"
          :disableSubTitleTooltip="true"
        />
        <AppNavigationList>
          <AppNavigationItem
            v-for="(item, index) in navigationItems"
            :key="index"
            :input-value="selectedSection === item.value"
            :title="item.title"
            :prependIcon="item.icon"
            @click="selectedSection = item.value"
          />
        </AppNavigationList>
      </v-navigation-drawer>

      <div class="w-100 pa-5 d-flex flex-column h-100">
        <div class="text-h6 font-weight-bold pb-5">
          <div class="d-flex align-center">
            <div>{{ currentTitle }}</div>
            <div class="ml-auto">
              <v-btn icon @click="localDialog = false">
                <v-icon>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <v-window v-model="selectedSection">
          <v-window-item value="details">
            <CorporateManagementDetails :item="item" />
          </v-window-item>

          <v-window-item value="analytics">
            <CorporateManagementAnalytics :item="item" />
          </v-window-item>

          <v-window-item value="accessControl">
            <CorporateManagementAccessControl
              :item="item"
              @update:accessesGiven="$emit('update:accessesGiven', $event)"
            />
          </v-window-item>
        </v-window>
        <v-spacer></v-spacer>
        <CorporateManagementDialogAlert
          v-if="isRevoked"
          :title="
            $t('settings.corporateManagement.detailsDialog.revokedAlert', {
              name: item?.owner?.name,
            })
          "
          type="error"
          show-delete
          @reInvite:click="onInvite"
          @delete:click="onDelete"
        />
        <CorporateManagementDialogAlert
          v-if="isInvitation"
          :title="
            $t('settings.corporateManagement.detailsDialog.invitedAlert', {
              name: item?.owner?.name,
            })
          "
          type="info"
          @reInvite:click="onInvite"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CorporateManagementDetails from "./CorporateManagementDetails.vue";
import CorporateManagementAnalytics from "./CorporateManagementAnalytics.vue";
import CorporateManagementAccessControl from "./CorporateManagementAccessControl.vue";
import CorporateManagementDialogAlert from "../CorporateManagementDialogAlert.vue";

export default {
  components: {
    CorporateManagementDetails,
    CorporateManagementAnalytics,
    CorporateManagementAccessControl,
    CorporateManagementDialogAlert,
  },
  props: {
    dialog: Boolean,
    item: Object,
    initialSection: {
      type: String,
      default: "details",
    },
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  data() {
    return {
      selectedSection: "details",
      navigationItems: [
        {
          title: this.$t(
            "settings.corporateManagement.detailsDialog.sections.details.title",
          ),
          value: "details",
          icon: this.$icons.REGULAR.ACTION.INFO,
        },
        {
          title: this.$t("common.analytics"),
          value: "analytics",
          icon: this.$icons.REGULAR.ACTION.ANALYTICS,
        },
        {
          title: this.$t("common.accessControl"),
          value: "accessControl",
          icon: this.$icons.REGULAR.ACTION.ACCESS_CONTROL,
        },
      ],
    };
  },
  watch: {
    localDialog: {
      handler(newVal) {
        if (newVal) {
          this.selectedSection = this.initialSection;
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    currentTitle() {
      const currentItem = this.navigationItems.find(
        (item) => item.value === this.selectedSection,
      );
      return currentItem ? currentItem.title : "";
    },
    isInvitation() {
      return this.item?.isInvitation;
    },
    isRevoked() {
      return this.item?.status === "REVOKED";
    },
    t() {
      return {
        fields: (key) =>
          this.$t(`settings.corporateManagement.fields.${key.split(".").join(".")}`),
        dialog: (key) =>
          this.$t(
            `settings.corporateManagement.detailsDialog.${key.split(".").join(".")}`,
          ),
      };
    },
  },
  methods: {
    onInvite() {
      this.$emit("invite", { emails: [this.item.owner.email] });
    },
    onDelete() {
      this.$emit("delete", { ids: [this.item.id] });
    },
  },
};
</script>

<style lang="scss" scoped>
.details-grid {
  display: grid;
  grid-template-columns: minmax(80px, 25%) 1fr;
  gap: 8px;
  .value {
    font-weight: 500;
  }
}
</style>
