import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let storageCancel;

const state = () => ({
  preview: null,
  storage: null,
  filesInFolder: null,
  isStorageLoading: false,
  isFilesLoading: false,
});

const getters = {
  isLoading: (state) => {
    return state.isStorageLoading;
  },
};

const actions = {
  async getStorage({ commit }, { id, load = true }) {
    if (load) {
      commit("storageLoading", true);
    }

    if (storageCancel) storageCancel();
    return axios
      .get(`${apiUrl}storage/file/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          storageCancel = c;
        }),
      })
      .then(async ({ data }) => {
        const storage = data.storage;
        commit("setStorage", storage);
        commit("storageLoading", false);
        return storage;
      })
      .catch((error) => {
        commit("storageLoading", false);
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  async restoreFileVersion({ commit }, { id, versionId }) {
    return axios
      .put(`${apiUrl}storage/file/${id}/restore`, { versionId })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async checkAndUpdatePreview({ commit, state }, { id }) {
    try {
      if (storageCancel) storageCancel();

      const { data } = await axios.get(`${apiUrl}storage/file/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          storageCancel = c;
        }),
      });
      const storage = data?.storage;
      const updatedAt = storage?.updatedAt;
      commit("setStorage", storage);
      if (state.storage && state.storage.updatedAt !== updatedAt) {
        return true;
      }
      return false;

      // todo: tell to refresh somehow
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
      return true;
    }
  },
  async getFilesInFolder({ commit }, folderId) {
    commit("loadingFiles", true);
    return axios
      .get(`${apiUrl}storage/folder/${folderId}/children`, {
        params: {
          type: "FILE",
        },
      })
      .then(async ({ data }) => {
        const { storages } = data;
        commit("setFiles", storages);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loadingFiles", false);
      });
  },
};

const mutations = {
  setPreview(state, preview) {
    state.preview = preview;
  },
  setStorage(state, storage) {
    state.storage = storage;
  },
  setFiles(state, filesInFolder) {
    state.filesInFolder = filesInFolder;
  },
  loadingFiles(state, isLoading) {
    state.isFilesLoading = isLoading;
  },
  storageLoading(state, isLoading) {
    state.isStorageLoading = isLoading;
  },
};

export const storagePreview = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
