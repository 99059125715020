<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteUser.active"
      :item="dialog.deleteUser.item"
      :title="$tc('users.deleteConfirmation.title', dialog.deleteUser?.item?.length)"
      :subtitle="$t('users.deleteConfirmation.description')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteUser"
    />
    <AppEditUserDialog
      v-model="dialog.editUser.active"
      :item="dialog.editUser.item"
      @afterEdit:completed="afterEdit"
    />
    <AppDataTable
      :headers="defaultHeaders"
      :items="users"
      :loading="isLoading"
      :noDataText="$t('common.noUsers')"
      :isStatsLoading="isUserStatsLoading"
      :statsText="statsText"
      :bulkActionConfigs="bulkActionConfigs"
      updateMutationType="settingsUsers/updateUser"
      show-select
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppUserTableItem
          :user="item"
          :canEdit="canEdit"
          :select="select"
          :isSelected="isSelected"
          :headers="headers"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :canDoActions="canDoActions"
          :canEditLabels="canEditLabels(item)"
          :jobTitleLabel="jobTitleLabel(item)"
          @user:onRoleChange="onRoleChange"
          @menu:click="onMenuClick"
          @cell:click="handleInteractableClick"
          @jobTitleLabel:click="openJobTitleMenu"
        />
      </template>
    </AppDataTable>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppActivityIndicator
      :show="isUpdatingStatus"
      :text="$t('users.userInvite.isUpdatingStatus')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onLabelChange"
      :labelType="labelType"
      selectedItemLabelPath="workTitleLabel"
    />
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { planRestrictionsMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    users: Array,
    isLoading: Boolean,
    currentUserId: String,
    canDoActions: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteUser: {
          active: false,
          item: null,
        },
        editUser: {
          active: false,
          item: null,
        },
      },
      contextMenuActiveItem: null,
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
      labelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      isContextMenuOpen: false,
    };
  },
  computed: {
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { INVITE, SET_INACTIVE, EDIT, DELETE } = this.$constant.ACTION_KEYS;

      const customActions = [
        {
          key: INVITE,
          props: {
            disabled: this.isInviteDisabled,
          },
          on: {
            click: this.onUsersInvite,
          },
        },
        {
          key: SET_INACTIVE,

          props: {
            disabled: this.isSetInactiveDisabled,
          },
          on: {
            click: this.setInactiveUser,
          },
        },
        {
          key: EDIT,
          props: {
            disabled: !this.canEditUser,
          },
          on: {
            click: this.onEditClick,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: this.isSetDeletedDisabled,
          },
          on: {
            click: this.onUsersDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.USER_ACTIONS,
      });

      return config;
    },
    canEditUser() {
      return this.canEdit && !this.contextMenuActiveItem?.isContact;
    },
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    ...mapState("settingsUsers", {
      isInviting: (state) => state.isInviting,
      isUpdatingStatus: (state) => state.isUpdatingStatus,
    }),
    ...mapState("userStats", {
      isUserStatsLoading: (state) => state.isLoading,
    }),
    ...mapGetters("userStats", {
      statsCountNotDeletedOrInactive: "statsCountNotDeletedOrInactive",
    }),
    statsText() {
      return this.$tc(
        "common.informationHeader.users",
        this.statsCountNotDeletedOrInactive,
      );
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          text: this.$t("users.name"),
          value: "fullName",
          show: true,
        },
        {
          text: this.$t("common.email"),
          value: "email",
          show: true,
        },
        {
          text: this.$t("users.phone"),
          value: "phone",
          sortable: false,
          show: true,
        },
        {
          text: this.$t("users.label.jobTitle.title"),
          value: "workTitleLabel.name",
          show: true,
          interactable: this.canEditLabels,
          menuRef: "labelMenuJobTitle",
        },
        {
          text: this.$t("users.adminPrivileges"),
          value: "isAdmin",
          align: "center",
          show: true,
          interactable: true,
          disabled: !this.canEdit,
        },
        {
          text: this.$t("users.hrAdminPrivileges"),
          value: "isHrAdmin",
          align: "center",
          compact: true,
          interactable: true,
          disabled: !this.canEdit,
        },

        {
          text: this.$t("users.status"),
          value: "status",
          compact: true,
        },
        {
          text: this.$t("common.id"),
          value: "number",
          compact: true,
        },
        {
          preset: "MENU",
        },
      ]);
    },
  },
  methods: {
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      // this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    isSetInactiveDisabled({ items }) {
      if (!this.canEdit) return true;
      if (items.every((item) => this.isCurrentUser(item))) return false;
      if (items.every((item) => this.hasStatus(item, this.$constant.INACTIVE)))
        return true;

      return false;
    },
    isInviteDisabled({ items }) {
      if (!this.canEdit) return true;
      if (items.every((item) => this.hasStatus(item, this.$constant.ACTIVE))) return true;

      return false;
    },
    isSetDeletedDisabled({ items }) {
      if (!this.canEdit) return true;

      if (items.every((item) => this.isCurrentUser(item))) return false;
      if (items.every((item) => this.hasStatus(item, this.$constant.DELETED)))
        return true;

      return false;
    },
    isCurrentUser(user) {
      return user?.id === this.currentUserId;
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", { type: this.labelType });
    },
    jobTitleLabel(item) {
      return this.labelsByType(this.labelType).find(
        (label) => label?.id === item?.workTitleLabel?.id,
      );
    },
    canEditLabels(item) {
      return (this.isDomainOwner || this.isDomainAdmin) && !item.isContact;
    },
    openJobTitleMenu({ from, item }) {
      this.$refs.labelMenuJobTitle.open({
        from,
        items: [item],
      });
    },
    onLabelChange({ items, label }) {
      const id = items[0].id;
      this.onDomainUserUpdate({
        id,
        labelId: label.id,
      });
    },
    getUserStats() {
      this.$store.dispatch("userStats/getStats");
    },
    hasStatus(item, status) {
      if (!item) return false;
      return item.status === status;
    },
    onMenuClick({ from, user }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = user;
      });
    },
    deleteUser({ item }) {
      this.onDomainUsersUpdate({
        ids: item.map((i) => i.id),
        status: this.$constant.DELETED,
      });
    },
    setInactiveUser({ items }) {
      this.onDomainUsersUpdate({
        ids: items.map((item) => item.id),
        status: this.$constant.INACTIVE,
      });
    },
    afterEdit() {
      this.$emit("afterEdit:completed");
    },
    onRoleChange(e) {
      this.$emit("user:onRoleChange", e);
    },
    onDomainUserUpdate(e) {
      this.$emit("user:onDomainUserUpdate", e);
    },
    onDomainUsersUpdate(e) {
      this.$emit("user:onDomainUsersUpdate", e);
    },
    onUsersInvite({ items }) {
      const hasNonInvitedUsers = items?.some(
        (item) => item?.status !== this.$constant.INVITED,
      );

      if (hasNonInvitedUsers && !this.planRestrictionsMixins_canInviteUser) {
        this.$emit("openIncreaseLicenseDialog");
      } else {
        const emails = items.map((item) => item.email);
        this.$emit("users:invite", { emails, items });
      }
    },
    onEditClick({ items }) {
      const item = items[0];
      this.dialog.editUser.active = true;
      this.dialog.editUser.item = item;
    },
    onUsersDelete({ items }) {
      this.dialog.deleteUser.active = true;
      this.dialog.deleteUser.item = items;
    },
  },
  mounted() {
    this.getUserStats();
    this.getLabels();
  },
};
</script>
