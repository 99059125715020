<template>
  <div>
    <div class="mb-4" v-if="property">
      <div class="text-h5 pb-4">
        {{ $t("integration.boligmappa.plantWizard.summaryStep.title") }}
      </div>
      <div class="pb-4">
        <div>{{ $t("common.address") }}:</div>
        <div class="font-weight-bold">{{ property.formatted }}</div>
      </div>
      <div class="pb-4" v-if="Array.isArray(property.propertyOwners)">
        <div>{{ $t("integration.boligmappa.plantWizard.summaryStep.homeowner") }}:</div>
        <div class="font-weight-bold">
          {{ propertyOwnerText(property.propertyOwners) }}
        </div>
      </div>
      <div>
        <div>{{ $t("integration.boligmappa.number") }}:</div>
        <div class="font-weight-bold">{{ property.boligmappaNumber }}</div>
      </div>
      <v-alert dense outlined type="error" v-if="hasError">
        Something went wrong, please try again.
      </v-alert>
    </div>
    <v-btn color="primary" @click="createPlant" :loading="isCreating">
      {{ $t("integration.boligmappa.integrationCard.connectButton") }}
    </v-btn>
    <v-btn text @click="goBack">
      {{ $t("common.back") }}
    </v-btn>
    <v-btn text @click="onCancel">
      {{ $t("common.cancel") }}
    </v-btn>
  </div>
</template>

<script>
import { createPlant } from "../../../../../../services/integration/boligmappa/BoligmappaPropertyWizardService";

export default {
  props: {
    property: Object,
    projectId: String,
  },
  data() {
    return {
      plant: null,
      isCreating: false,
      hasError: false,
    };
  },
  methods: {
    propertyOwnerText(owners) {
      if (!owners) return;
      return owners
        .map((o) => `${o.firstName ? o.firstName : ""} ${o.lastName ? o.lastName : ""}`)
        .join(", ");
    },
    createPlant() {
      if (!this.property) return;
      if (!this.projectId) return;
      this.isCreating = true;
      createPlant(this.property.boligmappaNumber, this.projectId)
        .then(({ response }) => {
          this.plant = response;
          this.$emit("complete");
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isCreating = false));
    },
    goBack() {
      this.$emit("back");
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
