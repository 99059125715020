<template>
  <div class="d-flex align-center" v-if="user">
    <AppDefaultTooltip top :disabled="showName">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary" :style="avatarSize" v-bind="attrs" v-on="on">
          <template v-if="avatar">
            <img style="object-fit: cover" :src="avatar" />
          </template>
          <template v-else-if="user.initials">
            <span class="white--text font-weight-bold" :style="initialsSize">
              {{ user.initials }}
            </span>
          </template>
        </v-avatar>
      </template>
      <span>
        {{ user[itemText] }}
      </span>
    </AppDefaultTooltip>
    <div class="d-flex flex-column">
      <template v-if="showName">
        <div
          class="pl-2"
          :class="boldName ? 'font-weight-bold' : ''"
          :style="truncate === 0 ? '' : 'white-space: nowrap'"
        >
          <AppTextTruncateValue :text="user[itemText]" :truncate="truncate" />
        </div>
      </template>
      <template v-if="showEmail || showPhone">
        <span class="pl-2 primary-text--text font-weight-normal">
          <AppDefaultTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <AppLinkText
                v-on="on"
                v-bind="attrs"
                v-if="showEmail && email"
                :link="`mailto:${email}`"
                underline-on-hover
              >
                {{ $t("common.sendEmail") }}
              </AppLinkText>
            </template>
            <span class="break-word">{{ email }}</span>
          </AppDefaultTooltip>
          <span v-if="showEmail && showPhone && phone && email"> | </span>
          <AppPhoneLinkText v-if="showPhone && phone" :phone="phone" />
        </span>
      </template>
    </div>
  </div>
  <div class="d-flex align-center" v-else>
    <AppDefaultTooltip top :disabled="!!noDataText">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary-border" :style="avatarSize" v-bind="attrs" v-on="on">
          <template>
            <span class="font-weight-normal" :small="small" :large="large">
              <v-icon color="primary">{{ $icons.SOLID.COMMON.USER }}</v-icon>
            </span>
          </template>
        </v-avatar>
      </template>
      <span> {{ noDataText || $t("common.unassigned") }}</span>
    </AppDefaultTooltip>
    <div v-if="noDataText" class="d-flex flex-column">
      <span class="pl-2"> {{ noDataText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    mediumLarge: {
      type: Boolean,
      default: false,
    },
    showName: Boolean,
    showEmail: Boolean,
    showPhone: Boolean,
    boldName: Boolean,
    truncate: {
      type: Number,
      default: 12,
    },
    noDataText: String,
    itemText: {
      type: String,
      default: "name",
    },
  },
  computed: {
    avatarSize() {
      var size = 26;
      if (this.small) size = 16;
      else if (this.mediumLarge) size = 32;
      else if (this.large) size = 42;
      return `width: ${size}px!important; height: ${size}px!important; min-width: ${size}px!important;`;
    },
    initialsSize() {
      var size = 0.7;
      if (this.large) size = 1.1;
      return `font-size: ${size}rem`;
    },
    avatar() {
      const avatar = this.user?.meta?.find((m) => m.field === "AVATAR");
      return avatar ? avatar.value : null;
    },
    phone() {
      if (this.user?.phone) return this.user.phone;
      const phone = this.user?.meta?.find((m) => m.field === "PHONE");
      return phone ? phone.value : null;
    },
    email() {
      if (this.user?.email) return this.user.email;
      const email = this.user?.meta?.find((m) => m.field === "EMAIL");
      return email ? email.value : null;
    },
  },
};
</script>
