<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.delete.active"
      :title="
        $tc(
          'settings.corporateManagement.deleteConfirmation.title',
          dialog.delete.item?.length,
        )
      "
      skip-validation
      :item="dialog.delete.item"
      @delete="({ item }) => onDelete({ ids: item?.map((item) => item.id) })"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="childDomains"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noChildDomains')"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="corporateManagement/removeChildDomain"
      updateMutationType="corporateManagement/updateChildDomain"
      show-select
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppCorporateManagementTableItem
          :item="item"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          @cell:click="handleInteractableClick"
          @name:click="openDetailsDialog({ items: [item] })"
          @menu:click="openContextMenu"
          @status:click="onStatusClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppCorporateManagementDetailsDialog
      v-model="dialog.details.active"
      :item="dialog.details.item"
      :initial-section="dialog.details.section"
      @update:accessesGiven="$emit('update:accessesGiven', $event)"
      @invite="$emit('invite', $event)"
      @delete="onDelete"
    />
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppGiveChildDomainAccessMenu
      ref="giveChildDomainAccessMenu"
      :items="itemsToEdit"
      @submit="$emit('update:accessesGiven', $event)"
    />
  </div>
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [filtersMixins],
  props: {
    childDomains: Array,
    isLoading: Boolean,
    tableModel: String,
    serverItemsLength: Number,
  },
  data() {
    return {
      dialog: {
        delete: {
          active: false,
          item: [],
        },
        details: {
          active: false,
          item: null,
          section: null,
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          value: this.filtersMixins_dataFields.NAME,
          text: this.$t("settings.corporateManagement.fields.name"),
        },
        {
          value: this.filtersMixins_dataFields.ORG_NR,
          text: this.$t("settings.corporateManagement.fields.orgNr"),
        },
        {
          value: this.filtersMixins_dataFields.STATUS,
          text: this.$t("settings.corporateManagement.fields.status"),
        },
        {
          value: this.filtersMixins_dataFields.PLAN,
          text: this.$t("settings.corporateManagement.fields.plan"),
        },
        {
          value: this.filtersMixins_dataFields.CONNECTED_AT,
          text: this.$t("settings.corporateManagement.fields.connectedDate"),
        },
        {
          value: this.filtersMixins_dataFields.OWNER,
          text: this.$t("settings.corporateManagement.fields.owner"),
        },
        {
          value: this.filtersMixins_dataFields.ACCESSES_GIVEN,
          text: this.$t("settings.corporateManagement.fields.accessesGiven"),
          menuRef: "giveChildDomainAccessMenu",
          interactable: true,
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { DETAILS, DELETE, ACCESS_CONTROL, ANALYTICS, EDIT_CHILD_DOMAIN_ACCESS } =
        this.$constant.ACTION_KEYS;

      const _openDetailsDialog = (section) => (e) => {
        this.openDetailsDialog({
          ...e,
          initialSection: section,
        });
      };

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: DETAILS,
          on: {
            click: _openDetailsDialog("details"),
          },
        },
        {
          key: ANALYTICS,
          on: {
            click: _openDetailsDialog("analytics"),
          },
        },
        {
          key: ACCESS_CONTROL,
          on: {
            click: _openDetailsDialog("accessControl"),
          },
        },
        {
          key: EDIT_CHILD_DOMAIN_ACCESS,
          on: {
            click: _openEditMenu("giveChildDomainAccessMenu"),
          },
        },
        {
          key: DELETE,
          on: {
            click: this.onChildDomainsDelete,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CORPORATE_MANAGEMENT_ACTIONS,
      });

      return config;
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    openDetailsDialog({ items, initialSection }) {
      this.dialog.details.active = true;
      this.dialog.details.item = items[0];
      this.dialog.details.section = initialSection;
    },
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    openContextMenu({ from, item, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = item;
      });
    },
    onChildDomainsDelete({ items }) {
      this.dialog.delete.item = items;
      this.dialog.delete.active = true;
    },
    onDelete({ ids }) {
      this.$emit("delete", { ids });
    },
  },
};
</script>
