<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="user"
      @click="handleInteractableClick"
    >
      <template v-if="header.value === 'fullName'">
        <AppUserAvatar :user="user" show-name :truncate="70" />
      </template>

      <template v-if="header.value === 'email'">
        <AppLinkText :link="`mailto:${user.email}`" underline-on-hover>
          <AppTextTruncateValue :text="user.email" :truncate="30" />
        </AppLinkText>
      </template>

      <template v-if="header.value === 'phone'">
        <AppPhoneLinkText :phone="phone" />
      </template>

      <template v-if="header.value === 'workTitleLabel.name'">
        <AppLabelBtn
          ref="jobTitleLabelBtn"
          @click="onJobTitleClick"
          :title="jobTitle"
          :isUpdating="user.isUpdatingLabel"
          :clickable="canEditLabels"
        />
      </template>

      <template v-if="header.value === 'isAdmin'">
        <AppDefaultCheckboxIcon :isSelected="user.isAdmin" />
        <!-- <div class="d-flex align-center justify-center">
          <v-checkbox
            :inputValue="user.isAdmin"
            @change="onRoleChange($event, 'ADMIN')"
            @click.stop
            :disabled="!canEdit"
          />
        </div> -->
      </template>

      <template v-if="header.value === 'isHrAdmin'">
        <AppDefaultCheckboxIcon :isSelected="user.isHrAdmin" />
        <!-- <div class="d-flex align-center justify-center">
          <v-checkbox
            :inputValue="user.isHrAdmin"
            @change="onRoleChange($event, 'HR_ADMIN')"
            @click.stop
            :disabled="!canEdit"
          />
        </div> -->
      </template>

      <template v-if="header.value === 'status'">
        <AppColorChip
          :text="
            $te(`common.userStatus.${user.status.toLowerCase()}`)
              ? $t(`common.userStatus.${user.status.toLowerCase()}`)
              : user.status
          "
          :color="userStatusColor"
        />
      </template>

      <template v-if="header.value === 'number'">
        {{ user.number }}
      </template>

      <template v-if="header.value === 'menu'">
        <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    user: Object,
    canEdit: Boolean,
    isHighlighted: Boolean,
    labels: Array,
    canEditLabels: Boolean,
    jobTitleLabel: Object,
    select: Function,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    headers: Array,
    dataFields: Object,
  },
  data() {
    return {
      isEditingWorkTitle: false,
      menu: {
        show: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    phone() {
      return this.getMetaValueByField("PHONE");
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    phoneRules() {
      return [
        this.form.phone.valid
          ? true
          : this.$t("settings.account.details.invalidPhoneNumber"),
      ];
    },

    userStatusColor() {
      let colorClass = "";
      switch (this.user.status) {
        case this.$constant.ACTIVE:
          colorClass = "success--text";
          break;
        case this.$constant.INVITED:
          colorClass = "pink--text";
          break;
        case this.$constant.INACTIVE:
          colorClass = "warning--text";
          break;
        case this.$constant.DELETED:
          colorClass = "error--text";
          break;
        case this.$constant.IMPORTED:
          colorClass = "purple--text";
          break;
      }
      return colorClass;
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        user: this.user,
        ...params,
      });
    },
    getMetaValueByField(field) {
      return this.user.meta.find((meta) => {
        return meta.field === field;
      })?.value;
    },
    onRoleChange(isAdmin, role) {
      this.$emit("user:onRoleChange", { user: this.user, isAdmin, role });
    },
    handleInteractableClick({ from, header }) {
      if (!header.interactable) return;

      if (header.value === "isAdmin") {
        this.onRoleChange(!this.user.isAdmin, "ADMIN");
        return;
      }
      if (header.value === "isHrAdmin") {
        this.onRoleChange(!this.user.isHrAdmin, "HR_ADMIN");
        return;
      }

      this.$emit("cell:click", {
        e: {
          from,
          items: [this.user],
        },
        header,
      });
    },
    onJobTitleClick() {
      this.$emit("jobTitleLabel:click", {
        from: this.$refs.jobTitleLabelBtn.$el,
        item: this.user,
      });
    },
  },
};
</script>
