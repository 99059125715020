<template>
  <v-icon
    size="20"
    :color="
      statusMixins_statusColor(status, loading, statusMixins_timeEntriesStatusItems)
    "
  >
    {{ icon }}
  </v-icon>
</template>

<script>
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    status: String,
    loading: Boolean,
  },
  computed: {
    icon() {
      return this.loading
        ? this.$icons.SOLID.ACTION.LOADING
        : this.$icons.SOLID.ACTION.STATUS;
    },
  },
};
</script>
