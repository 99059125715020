<template>
  <div>
    <AppContactDialog
      v-model="dialog.updateContact.active"
      :data="dialog.updateContact.item"
      :title="$t('contact.contactPerson.updateContact.title')"
      @confirm="afterContactUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteContact.active"
      :item="dialog.deleteContact.item"
      :title="
        $tc(
          'contact.contactPerson.deleteContact.title',
          dialog.deleteContact.item?.length,
        )
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.contactPerson.deleteContact.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteContact"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="contacts"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noContacts')"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="contacts/removeContact"
      updateMutationType="contacts/updateContact"
      :show-select="!projectId"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppContactTableItem
          :contact="item"
          :jobTitleLabel="getJobTitleLabel(item)"
          :responsibilityLabel="getResponsibilityLabel(item)"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
          :dataFields="dataFields"
          @cell:click="handleInteractableClick"
          @contact:update="updateContact"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onJobTitleLabelChange"
      :labelType="jobTitleLabelType"
      selectedItemLabelPath="label"
    />
    <AppLabelMenu
      ref="labelMenuResponsibility"
      @label:change="onResponsibilityLabelChange"
      :labelType="responsibilityLabelType"
      selectedItemLabelPath="relation.label"
    />
    <AppDataTableContextMenu
      v-if="!projectId"
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";
import { inviteUsers } from "@/services/auth/inviteUsers";
import { mapGetters } from "vuex";

export default {
  props: {
    contacts: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
  },
  data() {
    return {
      isInviting: false,
      dialog: {
        updateContact: {
          active: false,
          item: null,
        },
        deleteContact: {
          active: false,
          item: [],
        },
      },
      contextMenuActiveItem: null,
      jobTitleLabelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      responsibilityLabelType: this.$constant.LABEL_TYPE.RESPONSIBILITY,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { EDIT, INVITE, DELETE } = this.$constant.ACTION_KEYS;

      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: !!this.projectId,
          },
          on: {
            click: this.onContactEdit,
          },
        },
        {
          key: INVITE,
          props: {
            hidden: !!this.projectId,
            disabled: ({ items }) => this.isInviteDisabled({ items }),
          },
          on: {
            click: this.onInviteContactToWorkspace,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: !!this.projectId,
            hidden: !!this.projectId,
          },
          on: {
            click: this.onContactDelete,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CONTACT_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
          access: !this.projectId,
        },
        {
          preset: "NAME",
          value: this.dataFields.NAME,
          interactable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.customerSupplier")),
          value: this.dataFields.CLIENT,
        },
        {
          text: this.$options.filters.capitalize(this.$t("users.label.jobTitle.title")),
          value: this.dataFields.LABEL,
          disabled: !this.isDomainOwner && !this.isDomainAdmin,
          interactable: true,
          menuRef: "labelMenuJobTitle",
          compact: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("contact.contactPerson.label.responsibility.title"),
          ),
          value: this.dataFields.RELATION_LABEL,
          access: !!this.projectId,
          disabled: !this.isDomainOwner && !this.isDomainAdmin,
          interactable: true,
          menuRef: "labelMenuResponsibility",
          compact: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.phoneNumber")),
          value: this.dataFields.PHONE,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.email")),
          value: this.dataFields.EMAIL,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          compact: true,
          align: "center",
          disabled: !!this.projectId,
          interactable: true,
        },
        {
          preset: "MENU",
          access: !this.projectId,
        },
      ]);
    },
  },
  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      // this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isInviteDisabled({ items }) {
      if (!!this.projectId) return true;
      if (!this.isDomainOwner && !this.isDomainAdmin) return true;
      if (items.every((item) => this.hasStatus(item, this.$constant.ACTIVE))) return true;
      return false;
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    getResponsibilityLabel(item) {
      return this.labelsByType(this.responsibilityLabelType).find(
        (label) => label?.id === item?.relation?.labelId,
      );
    },
    getJobTitleLabel(item) {
      return this.labelsByType(this.jobTitleLabelType).find(
        (label) => label?.id === item?.labelId,
      );
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", {
        type: this.jobTitleLabelType,
      });
      if (!this.projectId) return;
      this.$store.dispatch("label/getLabels", {
        type: this.responsibilityLabelType,
      });
    },
    onInviteContactToWorkspace({ items }) {
      const showSnack = ({ text, color = "success" }) => {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text,
          color,
        });
      };

      if (items.some((item) => !item?.email)) {
        showSnack({
          text: this.$t("common.emailIsRequiredToInvite"),
          color: "error",
        });
        return;
      }

      this.isInviting = true;

      const body = {
        users: items.map((item) => ({
          email: item.email,
          role: "CONTACT",
          contactId: item.id,
        })),
      };

      inviteUsers({ body })
        .then((data) => {
          if (!!data?.message?.key) {
            const translationKeys = {
              userAlreadyInvited: "userHasBeenReinvited",
              contactNotFound: "contactNotFound",
            };
            const translationKey = translationKeys[data.message.key];
            const path = `users.userInvite.messages.${translationKey}`;

            if (translationKey && this.$te(path)) {
              showSnack({
                text: this.$t(path),
              });
              return;
            }
          }
          showSnack({
            text: this.$t("users.userInvite.messages.inviteSent"),
          });
        })
        .finally(() => {
          this.isInviting = false;
        });
    },
    hasStatus(item, status) {
      if (!item) return false;
      return item?.user?.status === status;
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onMenuClick({ from, contact }) {
      if (this.projectId) return;
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = contact;
      });
    },
    onContactDelete({ items }) {
      this.dialog.deleteContact.active = true;
      this.dialog.deleteContact.item = items;
    },
    deleteContact({ item }) {
      this.$emit("contact:delete", { items: item });
    },
    onContactEdit({ items }) {
      const item = items[0];
      this.dialog.updateContact.active = true;
      this.dialog.updateContact.item = item;
    },
    onJobTitleLabelChange({ items, label }) {
      const id = items[0].id;
      this.updateContact({
        id,
        body: {
          labelId: label.id,
          projectId: this.projectId,
        },
      });
    },
    onResponsibilityLabelChange({ items, label }) {
      const id = items[0].id;
      this.updateContact({
        id,
        body: {
          relation: {
            model: "project",
            modelId: this.projectId,
            labelId: label.id,
          },
        },
      });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
    afterContactUpdate() {
      this.$emit("contact:afterUpdate");
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>
