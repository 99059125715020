<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="localValue"
    v-bind="params"
    v-on="listeners"
    :search-input.sync="search"
    :noDataText="noDataText"
    :menuProps="{
      maxHeight: 400,
      maxWidth: menuMaxWidth,
      ...($attrs.menuProps ?? {}),
    }"
  >
    <template v-slot:prepend-inner v-if="userAvatar && selectedUser">
      <AppUserAvatar :user="selectedUser" />
    </template>
    <template v-slot:selection="{ index }" v-if="multiple">
      <template v-if="index === 0">
        {{ $tc("common.numSelected", localValue.length) }} {{ "&nbsp;" }}
      </template>
    </template>
    <template v-if="showSelectAll" v-slot:prepend-item>
      <v-list-item @mousedown.prevent @click="onSelectAll">
        <v-list-item-action>
          <AppDefaultCheckboxIcon
            :isIndeterminate="isSomeSelected"
            :isSelected="isAllSelected"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.selectAll") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      localValue: null,
      search: "",
      inputWidth: null,
    };
  },
  props: {
    value: {
      type: [String, Array, Number, Boolean, Object],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: String,
      default: "input-background",
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    userAvatar: Boolean,
    selectAll: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    truncate: {
      type: Number,
      default: 60,
    },
    activateOnFocus: {
      type: Boolean,
      default: false,
    },
    maxWidthMultiplier: {
      type: Number,
      default: 1.8,
    },
    resetOnSubmit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value: {
      handler(value) {
        this.localValue = value;
      },
      immediate: true,
    },
    //update menu dimensions if its and items length change so the menu doesn't float far
    //above the input (for example when many items change to a few)
    params: {
      handler(newVal, oldVal) {
        if (newVal.items?.length === oldVal.items?.length) return;
        if (!this.$refs.autocomplete?.isMenuActive) return;
        this.$refs.autocomplete.updateMenuDimensions();
      },
      deep: true,
    },
  },
  computed: {
    menuMaxWidth() {
      return this.inputWidth * this.maxWidthMultiplier || undefined;
    },
    isAllSelected() {
      if (!this.multiple || !this.itemsWithoutHeaders?.length) return false;
      return this.localValue?.length === this.itemsWithoutHeaders?.length;
    },
    itemsWithoutHeaders() {
      return this.items.filter((item) => !item.header);
    },
    isSomeSelected() {
      return this.localValue?.length > 0;
    },
    showSelectAll() {
      return this.multiple && this.selectAll && this.items.length;
    },
    selectedUser() {
      if (this.multiple) return null;
      if (!this.localValue) return null;
      return this.items.find((item) => item.id === this.localValue) || null;
    },
    noDataText() {
      return this.$attrs.loading
        ? this.$t("common.loading")
        : this.$attrs.noDataText || this.$t("common.noData");
    },
    listeners() {
      return {
        ...this.$listeners,
        change: this.onChange,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
  },
  methods: {
    onFocus() {
      if (this.activateOnFocus) {
        this.activateMenu();
      }
    },
    onSelectAll() {
      this.$nextTick(() => {
        if (this.isAllSelected) {
          this.localValue = [];
        } else {
          this.localValue = this.itemsWithoutHeaders.map((item) => {
            if (typeof item === "object") {
              return item[this.$props.itemValue];
            }
            return item;
          });
        }
      });
    },
    onChange(value) {
      this.localValue = value;
      if (!this.multiple) {
        this.emitValueChange();
      }

      if (this.showSelectAll) {
        setTimeout(() => {
          const index = this.$refs.autocomplete.$refs.menu.listIndex;
          this.$refs.autocomplete.$refs.menu.listIndex = index + 2;
        }, 10);
      }
    },
    onBlur() {
      if (this.multiple) {
        this.emitValueChange();
      }
    },
    emitValueChange() {
      if (_.isEqualWith(this.localValue, this.value, this.isEqualCustomizer)) return;
      this.$emit("change", this.localValue);
      if (this.resetOnSubmit) {
        this.reset();
      }
    },
    isEqualCustomizer(objValue, othValue) {
      //to ignore order if array
      if (_.isArray(objValue) && _.isArray(othValue)) {
        return _.isEqual(_.sortBy(objValue), _.sortBy(othValue));
      }
    },
    activateMenu() {
      this.$refs?.autocomplete?.activateMenu();
    },
    focus() {
      this.$refs?.autocomplete?.focus();
    },
    reset() {
      this.$refs.autocomplete.reset();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const observer = new MutationObserver(() => {
        const autocompleteEl = this.$refs?.autocomplete?.$el;
        if (autocompleteEl && autocompleteEl.clientWidth > 0) {
          this.inputWidth = autocompleteEl.clientWidth;
          observer.disconnect(); // Stop observing once the width is set
        }
      });

      const config = { attributes: true, childList: true, subtree: true };
      const autocompleteEl = this.$refs?.autocomplete?.$el;
      if (autocompleteEl) {
        observer.observe(autocompleteEl, config);
      }
    });
  },
};
</script>
