<template>
  <div>
    <div class="pb-4 d-flex align-center">
      <div class="d-flex align-center w-100">
        <AppDefaultTextField
          class="mw-250px mr-4"
          :label="$t('common.search')"
          :append-icon="$icons.LIGHT.ACTION.SEARCH"
          :value="filtersMixins_filterByKey('search')"
          @input="filtersMixins_debounceSearch"
          hide-details
          autofocus
          background-color="white"
          clearable
        />
        <v-spacer />
        <AppEditHeadersBtn @click="openEditHeadersDialog" />
      </div>
    </div>
    <AppClientTable
      ref="dataTable"
      :clients="clients"
      :isLoading="isLoading || filtersMixins_isLoadingFilters"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :getAllItems="getAllItems"
      @client:delete="onClientDelete"
      @client:update="onClientUpdate"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { filtersMixins } from "@/helpers/mixins";
import { getAllClients } from "@/services/client/allClients";

export default {
  mixins: [filtersMixins],
  props: {
    tableModel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDrawer: false,
      searchDebounce: null,
      dialog: {
        newClient: {
          active: false,
        },
        importClients: { active: false },
      },
    };
  },
  computed: {
    ...mapState("clients", {
      clients: (state) => state.clients,
      serverItemsLength: (state) => state.count,
      isLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  watch: {
    filtersMixins_filters: {
      handler() {
        this.getClients();
      },
      deep: true,
    },
  },
  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    async getAllItems() {
      return await getAllClients({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.projectId ? { projectId: this.projectId } : {}),
        },
      });
    },
    getClients() {
      this.$emit("data:get");
    },
    onClientUpdate({ id, body, snack = false }) {
      this.$store.dispatch("clients/updateClient", { id, body }).then(() => {
        if (snack) {
          this.$store.dispatch("snackbar/snackbar", {
            show: true,
            text: this.$t("common.updateSuccessful"),
            color: "success",
          });
        }
      });
    },
    onClientDelete({ items }) {
      this.$store.dispatch("clients/deleteClients", {
        ids: items.map((item) => item.id),
      });
    },
  },
  mounted() {
    this.filtersMixins_getFilters();
  },
};
</script>
