<template>
  <AppContextMenu
    ref="menu"
    :closeOnClick="!editStatusDialog"
    v-bind="$attrs"
    v-on="$listeners"
    max-height="600px"
    min-width="200px"
    :closeOnContentClick="false"
  >
    <AppChangeCustomStatusMenuItems
      :model="model"
      :statusId="statusId"
      @status:change="onSubmit"
      @gear:click="onGearClick"
    />
    <AppEditCustomStatusDialog v-model="editStatusDialog" :model="model" />
  </AppContextMenu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    model: String,
  },
  data() {
    return {
      editStatusDialog: false,
      statusId: null,
    };
  },
  watch: {
    statusesCount: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.$refs.menu.updateDimensions();
        }
      },
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      getStatusesByModel: "getStatusesByModel",
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
    statuses() {
      return this.getStatusesByModel(this.model);
    },
    status() {
      return this.getStatusByModelAndId({ model: this.model, id: this.statusId });
    },
    statusesCount() {
      if (!this.statuses) return;
      return Object.values(this.statuses).reduce(
        (total, statusArray) => total + statusArray.length,
        0,
      );
    },
  },
  methods: {
    onSubmit(statusId) {
      this.$emit("submit", { statusId });
      this.close();
    },
    onGearClick() {
      this.editStatusDialog = true;
    },
    getStatuses() {
      this.$store.dispatch("statuses/getStatuses", { model: this.model });
    },
    open(e) {
      const { items } = e;
      this.setData({ items });
      this._open(e);
    },
    _open(e) {
      this.$refs.menu.open(e);
    },
    close() {
      this.$refs.menu.close();
    },
    setData({ items }) {
      let statusId;
      if (items && items.length === 1) {
        statusId = items[0].statusId;
      }
      this.statusId = statusId;
    },
  },
  mounted() {
    if (!this.hasFetchedStatuses(this.model)) {
      this.getStatuses();
    }
  },
};
</script>
