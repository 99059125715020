<template>
  <AppDefaultTooltip v-if="showTooltip" top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon size="20" :color="color" v-bind="attrs" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    {{ name }}
  </AppDefaultTooltip>
  <v-icon v-else size="20" :color="color">
    {{ icon }}
  </v-icon>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    model: {
      type: String,
      required: true,
    },
    statusId: String,
    loading: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      isLoadingStatusesForModel: "isLoadingStatusesForModel",
    }),
    isLoadingStatuses() {
      return this.isLoadingStatusesForModel(this.model);
    },
    status() {
      return this.getStatusByModelAndId({ model: this.model, id: this.statusId });
    },
    icon() {
      if (this.isLoadingStatuses || this.loading) {
        return this.$icons.SOLID.ACTION.LOADING;
      }
      if (!this.status) {
        return this.$icons.SOLID.ACTION.NO_STATUS;
      }
      return this.$icons.SOLID.ACTION.STATUS;
    },
    color() {
      if (this.loading) return "primary";
      if (!this.status) return "";
      return this.status?.color;
    },
    name() {
      if (!this.status) {
        return this.$t("statuses.noStatus");
      }
      return this.status?.name;
    },
  },
};
</script>
