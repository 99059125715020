<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="item"
      @click="handleInteractableClick"
    >
      <template v-if="header.value == filtersMixins_dataFields.NAME">
        {{ item.companyName }}
      </template>
      <template v-if="header.value == filtersMixins_dataFields.ORG_NR">
        {{ item.orgNr }}
      </template>
      <template v-if="header.value == filtersMixins_dataFields.STATUS">
        <AppColorChip :text="statusText" :color="statusColor" />
      </template>
      <template v-if="header.value == filtersMixins_dataFields.PLAN">
        <AppColorChip :text="item?.plan?.name" color="info--text" />
      </template>
      <template v-if="header.value == filtersMixins_dataFields.CONNECTED_AT">
        <template v-if="item.connectedAt">
          <div class="d-flex align-center">
            <div>{{ formatDate(item.connectedAt) }}</div>
          </div>
        </template>
      </template>
      <template v-if="header.value == filtersMixins_dataFields.OWNER">
        {{ item.owner.name }}
      </template>
      <template v-if="header.value == filtersMixins_dataFields.ACCESSES_GIVEN">
        {{ formatAccessesGiven(item.accessesGiven) }}
      </template>
      <template v-if="header.value == filtersMixins_dataFields.MENU">
        <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import moment from "moment";
import { groupByMixins } from "@/helpers/mixins";
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins, dataTableItemMixins],
  props: {
    item: Object,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    headers: Array,
    tableModel: String,
    select: Function,
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    statusText() {
      return this.$t(`settings.corporateManagement.status.${this.item.status}`);
    },
    statusColor() {
      const colors = {
        PENDING: "warning",
        REVOKED: "error",
        EXPIRED: "error",
        ACTIVE: "success",
      };
      return colors[this.item.status] + "--text";
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        item: this.item,
        ...params,
      });
    },
    onNameClick() {
      this.$emit("name:click");
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    handleInteractableClick({ from, header }) {
      if (!header.interactable) return;

      if (header.value === this.filtersMixins_dataFields.NAME) {
        this.onNameClick();
        return;
      }

      this.$emit("cell:click", {
        e: {
          from,
          items: [this.item],
        },
        header,
      });
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD.MM.YYYY");
    },
    formatAccessesGiven(accesses) {
      if (!accesses) return "";
      return Object.entries(accesses)
        .filter(([_, value]) => value === true)
        .map(([key]) =>
          this.$t(
            `settings.corporateManagement.detailsDialog.sections.accessControl.cards.${key}.title`,
          ),
        )
        .join(", ");
    },
  },
};
</script>

<style>
.v-small-dialog__activator__content {
  float: left;
}
</style>
