<template>
  <v-btn
    class="ml-1"
    :color="showIndicator ? '#d1e5e8' : 'primary'"
    depressed
    :text="!showIndicator"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ text }}
    <v-icon v-if="!showIndicator" class="ml-2">
      {{ icon }}
    </v-icon>
    <div
      v-else
      class="font-weight-bold ml-2 rounded-sm info--background"
      style="padding: 2px 4px"
    >
      <div style="color: white">
        {{ indicatorText }}
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    showIndicator: Boolean,
    icon: String,
    indicatorText: [String, Number],
  },
};
</script>
