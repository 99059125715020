import axios from "axios";
import router from "@/router";

const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  ownedDomains: [],
  inviterDomain: null,
  isLoading: false,
  isAccepting: false,
});

const getters = {};

const actions = {
  async getChildDomainInvitation({ commit }, { token }) {
    commit("setIsLoading", true);
    return axios
      .get(`${apiUrl}child-domain-invitation/${token}`)
      .then(({ data }) => {
        if (data.success) {
          commit("setOwnedDomains", data.ownedDomains);
          commit("setInviterDomain", data.inviterDomain);
          return data;
        } else {
          handleError({ error: data.error, token });
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("setIsLoading", false);
      });
  },
  async acceptChildDomainInvitation({ commit }, { token, domainIds }) {
    commit("setIsAccepting", true);
    return axios
      .post(`${apiUrl}child-domain-invitation/${token}/accept`, { domainIds })
      .then(({ data }) => {
        if (data.success) {
          handleSuccessInvitation();
          return data;
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("setIsAccepting", false);
      });
  },
};

const mutations = {
  setOwnedDomains(state, ownedDomains) {
    state.ownedDomains = ownedDomains;
  },
  setInviterDomain(state, inviterDomain) {
    state.inviterDomain = inviterDomain;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsAccepting(state, isAccepting) {
    state.isAccepting = isAccepting;
  },
};

// Updated helper function to handle errors and navigate with email
function handleError({ error }) {
  const { code } = error;

  switch (code) {
    case "INVALID_USER":
      router.push({
        name: "invalidUser",
      });
      break;
    case "INVALID_TOKEN":
      router.push({ name: "forbidden" });
      break;
    default:
      router.push({ name: "error" });
  }
}

function handleSuccessInvitation() {
  location.href = "/";
}

export const childDomainInvitation = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
