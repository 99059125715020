<template>
  <div>
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteDialog.active"
      :item="dialog.deleteDialog.item"
      :title="$tc('cms.template.form.delete.title', dialog.deleteDialog.item.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="templateDelete"
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="FORM_TEMPLATE"
      @submit="onTemplateCopy"
    />
    <AppDataTable
      :loading="isLoading"
      :headers="defaultHeaders"
      :items="templates"
      :noDataText="$t('common.noTemplates')"
      :bulkActionConfigs="bulkActionConfigs"
      :removeMutationType="removeMutationType"
      :updateMutationType="updateMutationType"
      show-select
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppFormTemplateTableItem
          :template="item"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          @edit:click="onTemplateEditClick"
          @menu:click="openContextMenu"
          @cell:click="handleInteractableClick"
        />
      </template>
    </AppDataTable>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppIsActivePickerMenu
      ref="isSignableMenu"
      @submit="({ isActive }) => onTemplatesUpdate({ hasDefaultSignature: isActive })"
    />
    <AppIsActivePickerMenu
      ref="shareableMenu"
      :activeLabelKey="$t('common.shareable')"
      :inactiveLabelKey="$t('common.notShareable')"
      @submit="
        ({ isActive }) => onTemplatesUpdate({ isShareableWithChildDomains: isActive })
      "
    />
    <AppIsActivePickerMenu
      ref="publishedStatusMenu"
      :activeLabelKey="$t('common.published')"
      :inactiveLabelKey="$t('common.unpublished')"
      @submit="({ isActive }) => onTemplatesUpdate({ isPublished: isActive })"
    />
    <AppFormCategoryPickerMenu
      ref="categoryMenu"
      @submit="({ categoryId }) => onTemplatesUpdate({ categoryId })"
    />
  </div>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";
import { mapGetters } from "vuex";

export default {
  props: {
    templates: Array,
    isLoading: Boolean,
    isCms: Boolean,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      assignableProjects: [],
      isAssignableProjectsLoading: false,
      dialog: {
        deleteDialog: {
          active: false,
          item: [],
        },
        copyDialog: {
          active: false,
          items: [],
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("auth", {
      isCorporateManagementFeatureEnabled: "isCorporateManagementFeatureEnabled",
      hasParentDomain: "hasParentDomain",
      isDomainElevatedUser: "isDomainElevatedUser",
    }),
    removeMutationType() {
      return this.isCms
        ? "cmsFormTemplates/removeTemplate"
        : "formTemplates/removeTemplate";
    },
    updateMutationType() {
      return this.isCms
        ? "cmsFormTemplates/updateTemplate"
        : "formTemplates/updateTemplate";
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const {
        EDIT,
        DELETE,
        COPY,
        EDIT_CATEGORY,
        EDIT_PUBLISHED_STATUS,
        EDIT_IS_SIGNABLE,
        EDIT_IS_SHAREABLE,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: EDIT,
          on: {
            click: this.onTemplateEditClick,
          },
        },
        {
          key: COPY,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: this.onCopyClick,
          },
        },
        {
          key: EDIT_CATEGORY,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: _openEditMenu("categoryMenu"),
          },
        },
        {
          key: EDIT_IS_SIGNABLE,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: _openEditMenu("isSignableMenu"),
          },
        },
        {
          key: EDIT_PUBLISHED_STATUS,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: _openEditMenu("publishedStatusMenu"),
          },
        },
        {
          key: EDIT_IS_SHAREABLE,
          props: {
            hidden:
              this.isCms ||
              !this.isCorporateManagementFeatureEnabled ||
              !this.isDomainElevatedUser,
          },
          on: {
            click: _openEditMenu("shareableMenu"),
          },
        },
        {
          key: DELETE,
          on: {
            click: this.onTemplateDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_TEMPLATE_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          text: this.$t("cms.template.form.name"),
          value: "name",
        },
        {
          text: this.$t("common.status"),
          value: "isPublished",
          menuRef: "publishedStatusMenu",
          interactable: true,
        },
        {
          text: this.$t("common.category"),
          value: "category.name",
          menuRef: "categoryMenu",
          interactable: true,
        },
        {
          access: this.hasParentDomain,
          text: this.$t("common.itemOrigin"),
          value: "origin",
          compact: true,
        },
        {
          access:
            !this.isCms &&
            this.isCorporateManagementFeatureEnabled &&
            this.isDomainElevatedUser,
          text: this.$t("common.shareable"),
          value: "isShareableWithChildDomains",
          menuRef: "shareableMenu",
          interactable: true,
        },
        {
          preset: "DATE",
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          interactable: false,
        },
        {
          preset: "MENU",
        },
      ]);
    },
  },
  methods: {
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onTemplateEditClick({ items }) {
      const template = items?.[0];
      this.$emit("template:editClick", { template });
    },
    onTemplateDelete({ items }) {
      this.dialog.deleteDialog.active = true;
      this.dialog.deleteDialog.item = items;
    },
    templateDelete({ item }) {
      this.$emit("template:delete", { items: item });
    },
    onTemplatesUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("templates:update", { body });
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onTemplateCopy(items) {
      this.$emit("template:copy", { items });
    },
    openContextMenu({ from, template }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = template;
      });
    },
  },
};
</script>
